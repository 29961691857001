// ** React Imports
import { useEffect, useMemo, useState } from 'react'

// ** Component Imports
import FirmwarePageView from './firmware-page'

// ** Redux Imports
import {
  useLazyFindFirmwareListQuery,
  useFindModelListNoPaginationQuery,
  useDeleteFirmwareMutation,
} from '../../services'

// ** Type Imports
import { Firmware } from '@/types'
import type { GetFirmwareParmas } from '@/types/firmware'

// ** Util Imports
import useInput from '../../hooks/useInput'
import { isUndefined } from 'lodash'

const FirmwarePage = () => {
  const {
    data: query,
    handler: setQuery,
    setData: setDataQuery,
  } = useInput<GetFirmwareParmas>({
    modelId: 0,
  })

  const [page, setPage] = useState<number>(0)

  const [firmwareList, setFirmwareList] = useState<Firmware[]>([])
  const [count, setCount] = useState<number>(0)
  const [firmwareId, setFirmwareId] = useState<number>(0)

  const [open, setOpen] = useState<boolean>(false)
  const [delOpen, setDelOpen] = useState<boolean>(false)

  const [isNew, setIsNew] = useState<boolean>(false)

  const [reRenderSwitch, setReRenderSwitch] = useState<boolean>(false)

  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const [findFirmwareListApi] = useLazyFindFirmwareListQuery()
  const [deleteFirmwareApi] = useDeleteFirmwareMutation()

  const { data: modelList } = useFindModelListNoPaginationQuery('')

  const modelData = useMemo(
    () => (isUndefined(modelList) ? [] : modelList.data.data),
    [modelList],
  )

  const handleOpen = (state: boolean, id?: number) => {
    setOpen(true)
    setIsNew(state)
    if (id) {
      setFirmwareId(id)
    }
  }
  const handleDelOpen = () => setDelOpen(true)
  const handleClose = () => setOpen(false)
  const handleDelClose = () => setDelOpen(false)
  const handleRefetch = () => setReRenderSwitch(true)

  const handleInit = () => {
    setDataQuery({ modelId: 0 })
    handleRefetch()
  }

  const args = () => {
    const arg: { page: number; pageSize: number; modelId?: number } = {
      page,
      pageSize: 10,
    }

    if (query.modelId !== 0) arg.modelId = query.modelId

    return arg
  }

  const delContent = () => {
    if (selectedRows.length < 1) {
      alert('1개 이상을 선택해주세요.')
      handleDelClose()

      return
    }

    deleteFirmwareApi({ firmwareIds: selectedRows })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('삭제를 성공했습니다.')
          setSelectedRows([])
          handleRefetch()
          handleDelClose()
        }
      })
      .catch((err) => alert(err.data.message))
  }

  useEffect(() => {
    if (reRenderSwitch) {
      setReRenderSwitch(false)
    }

    findFirmwareListApi(args())
      .unwrap()
      .then((res) => {
        setFirmwareList(res.data.data)
        setCount(res.data.count)
      })
  }, [page, reRenderSwitch])

  return (
    <FirmwarePageView
      data={firmwareList}
      page={page}
      setPage={setPage}
      count={count}
      open={open}
      handleClose={handleClose}
      isNew={isNew}
      handleOpen={handleOpen}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      handleRefetch={handleRefetch}
      modelData={modelData}
      setQuery={setQuery}
      query={query}
      firmwareId={firmwareId}
      delContent={delContent}
      delOpen={delOpen}
      handleDelClose={handleDelClose}
      handleDelOpen={handleDelOpen}
      handleInit={handleInit}
    />
  )
}

export default FirmwarePage
