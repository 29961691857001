// ** React Imports
import { useMemo, useState } from 'react'

// ** Component Imports
import AdminPageView from './admin-page'

// ** Redux Imports
import { useDeleteAdminMutation, useFindAdminListQuery } from '../../services'

// ** Utils Imports
import { isUndefined } from 'lodash'

const AdminPage = () => {
  const [page, setPage] = useState<number>(0)

  const [open, setOpen] = useState<boolean>(false)
  const [delOpen, setDelOpen] = useState<boolean>(false)

  const [isNew, setIsNew] = useState<boolean>(false)

  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const [delAdminApi] = useDeleteAdminMutation()
  const { data, refetch } = useFindAdminListQuery({ page, pageSize: 10 })

  const adminData = useMemo(
    () =>
      isUndefined(data)
        ? { data: [], count: 0 }
        : { data: data.data.data, count: data.data.count },
    [data],
  )

  const handleOpen = (state: boolean) => {
    setOpen(true)
    setIsNew(state)
  }
  const handleDelOpen = () => setDelOpen(true)

  const handleClose = () => setOpen(false)
  const handleDelClose = () => setDelOpen(false)

  const delContent = () => {
    if (selectedRows.length < 1) {
      alert('1개 이상을 선택하세요.')
      handleDelClose()

      return
    }

    delAdminApi({ ids: selectedRows })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('삭제 되었습니다.')
          handleDelClose()
          refetch()
        }
      })
      .catch((err) => alert(err.data.message))
  }

  return (
    <AdminPageView
      data={adminData.data}
      open={open}
      isNew={isNew}
      handleClose={handleClose}
      handleOpen={handleOpen}
      page={page}
      setPage={setPage}
      count={adminData.count}
      handleRefetch={refetch}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      delContent={delContent}
      delOpen={delOpen}
      handleDelClose={handleDelClose}
      handleDelOpen={handleDelOpen}
    />
  )
}

export default AdminPage
