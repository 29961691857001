// ** React Imports
import { ChangeEvent, useEffect } from 'react'

// ** Mui Imports
import {
  Box,
  Card,
  Button,
  Typography,
  Stack,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Tab,
  Tabs,
  SelectChangeEvent,
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'

// ** Other Imports
import { LoginHistory, InfoAccessLog, Dates } from '@/types'
import dayjs from 'dayjs'
import DateRangePicker from '../../components/datepicker'

// ** Type Imports
import type { ManageHistoryParmas } from '@/types/history'

const defaultColumns: GridColumns = [
  {
    flex: 0.15,
    field: 'id',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '번호',
    renderCell: ({ row }: { row: LoginHistory }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.id}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'email',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '아이디',
    renderCell: ({ row }: { row: LoginHistory }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.user.email}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'IP',
    minWidth: 30,
    headerAlign: 'center',
    headerName: 'IP',
    renderCell: ({ row }: { row: LoginHistory }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.ip}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'os',
    minWidth: 30,
    headerAlign: 'center',
    headerName: 'OS',
    renderCell: ({ row }: { row: LoginHistory }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.os}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'access_date',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '접속 시간',
    renderCell: ({ row }: { row: LoginHistory }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {dayjs(row.regDate).format('YYYY.MM.DD HH:mm:ss')}
        </Typography>
      </Box>
    ),
  },
]
const defaultColumns2: GridColumns = [
  {
    flex: 0.15,
    field: 'id',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '번호',
    renderCell: ({ row }: { row: InfoAccessLog }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.id}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'access_date',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '로그일',
    renderCell: ({ row }: { row: InfoAccessLog }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {dayjs(row.regDate).format('YYYY.MM.DD HH:mm:ss')}
        </Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'page',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '페이지명',
    renderCell: ({ row }: { row: InfoAccessLog }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.page}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'type',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '유형',
    renderCell: ({ row }: { row: InfoAccessLog }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.type ? row.type : '-'}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'IP',
    minWidth: 30,
    headerAlign: 'center',
    headerName: 'IP',
    renderCell: ({ row }: { row: InfoAccessLog }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.ip}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'admin',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '조회자',
    renderCell: ({ row }: { row: InfoAccessLog }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.admin.username}</Typography>
      </Box>
    ),
  },
]

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface Props {
  loginData: LoginHistory[]
  manageData: InfoAccessLog[]
  query: any
  dates: Dates
  value: number
  count: number
  page: number
  manageQuery: any
  setSelectManageQuery: (e: SelectChangeEvent<HTMLInputElement>) => void
  setQuery: (e: ChangeEvent<HTMLInputElement>) => void
  setManageQuery: (e: ChangeEvent<HTMLInputElement>) => void
  setValue: (e: number) => void
  setDates: (e: any) => void
  handleRefetch: () => any
  handleQueryInit: () => void
  setPage: (e: number) => void
}

const UserHistoryView = ({
  loginData,
  count,
  manageData,
  query,
  setQuery,
  dates,
  setDates,
  page,
  setPage,
  handleRefetch,
  value,
  handleQueryInit,
  setValue,
  setManageQuery,
  manageQuery,
  setSelectManageQuery,
}: Props) => {
  const columns = [...defaultColumns]
  const columns2 = [...defaultColumns2]

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="로그인 목록" {...a11yProps(0)} />
            <Tab label="개인정보 조회 목록" {...a11yProps(1)} />
          </Tabs>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {value === 0 ? (
          <Card sx={{ p: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <DateRangePicker dates={dates} setDates={setDates} />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="아이디 검색"
                  placeholder="아이디를 입력하세요."
                  value={query.name}
                  name="name"
                  onChange={setQuery}
                />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={1} sx={{ mt: 1 }}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={handleRefetch}
                >
                  조회
                </Button>
              </Grid>
              <Grid item xs={1} sx={{ mt: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  fullWidth
                  onClick={handleQueryInit}
                >
                  초기화
                </Button>
              </Grid>
            </Grid>
          </Card>
        ) : (
          <Card sx={{ p: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <DateRangePicker dates={dates} setDates={setDates} />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="아이디 검색"
                  placeholder="아이디를 입력하세요."
                  value={query.admin}
                  name="admin"
                  onChange={setManageQuery}
                />
              </Grid>
              <Grid item xs={1}>
                <FormControl fullWidth>
                  <InputLabel id="page">페이지명</InputLabel>
                  <Select
                    labelId="page"
                    label="페이지명"
                    value={manageQuery.pageName}
                    name="pageName"
                    onChange={setSelectManageQuery}
                  >
                    <MenuItem value="">전체</MenuItem>
                    <MenuItem value="사용자 조회">사용자 조회</MenuItem>
                    <MenuItem value="활성화 관리">활성화 관리</MenuItem>
                    <MenuItem value="고객 현황">고객 현황</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <FormControl fullWidth>
                  <InputLabel id="type">유형</InputLabel>
                  <Select
                    labelId="type"
                    label="유형"
                    value={manageQuery.type}
                    name="type"
                    onChange={setSelectManageQuery}
                    disabled={manageQuery.pageName === '' ? true : false}
                  >
                    <MenuItem value="">전체</MenuItem>
                    <MenuItem value="READ">조회</MenuItem>
                    {query.pageName === '활성화 관리' && (
                      <MenuItem value="ACTIVE">활성화</MenuItem>
                    )}
                    {query.pageName === '활성화 관리' && (
                      <MenuItem value="INACTIVE">정지</MenuItem>
                    )}
                    {query.pageName !== '활성화 관리' && (
                      <MenuItem value="EXCEL">엑셀 다운로드</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} sx={{ mt: 1 }}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={handleRefetch}
                >
                  조회
                </Button>
              </Grid>
              <Grid item xs={1} sx={{ mt: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  fullWidth
                  onClick={handleQueryInit}
                >
                  초기화
                </Button>
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
      <Grid item xs={12}>
        <Card>
          <TabPanel value={value} index={0}>
            <DataGrid
              columns={columns}
              rows={loginData}
              rowCount={count}
              autoHeight
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              disableColumnMenu
              checkboxSelection
              pagination
              page={page}
              paginationMode="server"
              pageSize={10}
              onPageChange={(newPage) => {
                setPage(newPage)
              }}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    등록된 정보가 없습니다.
                  </Stack>
                ),
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DataGrid
              columns={columns2}
              rows={manageData}
              rowCount={count}
              autoHeight
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              disableColumnMenu
              checkboxSelection
              pagination
              page={page}
              paginationMode="server"
              pageSize={10}
              onPageChange={(newPage) => {
                setPage(newPage)
              }}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    등록된 정보가 없습니다.
                  </Stack>
                ),
              }}
            />
          </TabPanel>
        </Card>
      </Grid>
    </Grid>
  )
}

export default UserHistoryView
