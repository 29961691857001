// ** React Imports
import { useEffect, useState } from 'react'

// ** Component Imports
import AppVersionPageView from './app-version-page'

// ** Other Imports
import { AppVersion, Dates } from '@/types'
import dayjs from 'dayjs'
import {
  useDeleteAppVersionMutation,
  useLazyFindAppVersionListQuery,
} from '../../services'

const AppVersionPage = () => {
  const [dates, setDates] = useState<Dates>({
    startDate: dayjs().subtract(1, 'month'),
    endDate: dayjs(),
  })

  const [open, setOpen] = useState<boolean>(false)
  const [delOpen, setDelOpen] = useState<boolean>(false)

  const [page, setPage] = useState<number>(0)

  const [appVersionData, setAppVersionData] = useState<AppVersion[]>([])
  const [count, setCount] = useState<number>(0)

  const [reRenderSwitch, setReRenderSwitch] = useState<boolean>(false)

  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const [findAppVersionListApi] = useLazyFindAppVersionListQuery()
  const [deleteApi] = useDeleteAppVersionMutation()

  const handleOpen = () => setOpen(true)
  const handleDelOpen = () => setDelOpen(true)

  const handleClose = () => setOpen(false)
  const handleDelClose = () => setDelOpen(false)

  const handleRefetch = () => setReRenderSwitch(true)
  const handleQueryInit = () => {
    setDates({ startDate: dayjs().subtract(1, 'day'), endDate: dayjs() })
    handleRefetch()
  }

  const delContent = () => {
    if (selectedRows.length < 1) {
      alert('1개 이상의 데이터를 선택해주세요.')
      handleDelClose()

      return
    }

    deleteApi({ appVersionIds: selectedRows })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('앱 버전을 삭제했습니다.')
          handleDelClose()
          handleRefetch()
        }
      })
      .catch((err) => alert(err.data.message))
  }

  useEffect(() => {
    if (reRenderSwitch) {
      setReRenderSwitch(false)
    }
    findAppVersionListApi({
      startDate: dayjs(dates.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(dates.endDate).add(1, 'day').format('YYYY-MM-DD'),
    })
      .unwrap()
      .then((res) => {
        setAppVersionData(res.data.data)
        setCount(res.data.count)
      })
  }, [page, reRenderSwitch])

  return (
    <AppVersionPageView
      data={appVersionData}
      count={count}
      open={open}
      dates={dates}
      setDates={setDates}
      handleClose={handleClose}
      handleOpen={handleOpen}
      page={page}
      setPage={setPage}
      handleRefetch={handleRefetch}
      handleQueryInit={handleQueryInit}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      delContent={delContent}
      delOpen={delOpen}
      handleDelClose={handleDelClose}
      handleDelOpen={handleDelOpen}
    />
  )
}

export default AppVersionPage
