// ** React Imports
import { useEffect, useMemo, useState } from 'react'

// ** Component Imports
import UserManualModalView from './user-manual-modal'

//** Redux Imports
import {
  useFindModelListNoPaginationQuery,
  useLazyFindManualQuery,
  useSaveManualMutation,
  useUpdateManualMutation,
} from '../../../services'

// ** Utils Imports
import { isUndefined } from 'lodash'
import useInput from '../../../hooks/useInput'

// ** type Imports
import type { ManualParams } from '@/types/manual'

interface Props {
  state: boolean
  isNew: boolean
  manualId: number
  manualRefetch: () => void
  closeEvent: () => void
}

const UserManualModal = ({
  state,
  closeEvent,
  isNew,
  manualId,
  manualRefetch,
}: Props) => {
  const {
    data: query,
    handler: setQuery,
    setData,
  } = useInput<ManualParams>({
    modelId: 0,
    name: '',
    filePath: '',
    fileName: '',
  })

  const [open, setOpen] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleEditOpen = () => setEditOpen(true)
  const handleEditClose = () => setEditOpen(false)

  const { data: modelList } = useFindModelListNoPaginationQuery('')

  const [manualSaveApi] = useSaveManualMutation()
  const [findManualApi] = useLazyFindManualQuery()
  const [manualUpdateApi] = useUpdateManualMutation()

  const modelData = useMemo(
    () => (isUndefined(modelList) ? [] : modelList.data.data),
    [modelList],
  )

  const regContent = () => {
    if (query.modelId === 0) {
      alert('모델을 선택해주세요.')

      return
    }

    if (query.name === '') {
      alert('이름을 입력해주세요.')

      return
    }

    if (query.filePath === '') {
      alert('파일을 업로드해주세요.')

      return
    }

    manualSaveApi(query)
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          setData({ modelId: 0, fileName: '', filePath: '', name: '' })
          manualRefetch()
          closeEvent()
          handleClose()
        }
      })
      .catch((err) => {
        alert(err.data.message)
        handleClose()
      })
  }

  const modContent = () => {
    if (query.modelId === 0) {
      alert('모델을 선택해주세요.')

      return
    }

    if (query.name === '') {
      alert('이름을 입력해주세요.')

      return
    }

    if (query.filePath === '') {
      alert('파일을 업로드해주세요.')

      return
    }

    manualUpdateApi(query)
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          setData({ modelId: 0, fileName: '', filePath: '', name: "'" })
          manualRefetch()
          closeEvent()
          handleEditClose()
        }
      })
      .catch((err) => {
        alert(err.data.message)
        handleEditClose()
      })
  }

  const setFilePath = (filePath: string) => {
    setData((cur: any) => ({ ...cur, filePath }))
  }

  const setFileName = (fileName: string) => {
    setData((cur: any) => ({ ...cur, fileName }))
  }

  useEffect(() => {
    if (!isNew) {
      findManualApi(manualId)
        .unwrap()
        .then((res) => {
          setData({
            modelId: res.data.model.id,
            name: res.data.name,
            filePath: res.data.filePath,
            fileName: res.data.fileName,
            manualId: res.data.id,
          })
        })
        .catch((err) => alert(err.data.message))
    }
  }, [state])

  return (
    <UserManualModalView
      state={state}
      closeEvent={closeEvent}
      isNew={isNew}
      query={query}
      setQuery={setQuery}
      modelData={modelData}
      regContent={regContent}
      setFilePath={setFilePath}
      modContent={modContent}
      setFileName={setFileName}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      editOpen={editOpen}
      handleEditClose={handleEditClose}
      handleEditOpen={handleEditOpen}
    />
  )
}

export default UserManualModal
