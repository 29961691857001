// ** React Imports
import { useMemo, useState } from 'react'

// ** Component Imports
import UpdatePageView from './update-page'

// ** Redux Imports
import {
  useDeleteUpdaterMutation,
  useFindUpdaterListQuery,
} from '../../services'

// ** Util Imports
import { isUndefined } from 'lodash'

const UpdatePage = () => {
  const [page, setPage] = useState<number>(0)

  const [open, setOpen] = useState<boolean>(false)
  const [delOpen, setDelOpen] = useState<boolean>(false)

  const [isNew, setIsNew] = useState<boolean>(false)

  const [updaterId, setUpdaterId] = useState<number>(0)
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const [delUpdaterApi] = useDeleteUpdaterMutation()
  const { data, refetch } = useFindUpdaterListQuery({ page, pageSize: 10 })
  const updaterList = useMemo(
    () =>
      isUndefined(data)
        ? { data: [], count: 0 }
        : { data: data.data.data, count: data.data.count },
    [data],
  )

  const delContent = () => {
    if (selectedRows.length < 1) {
      alert('1개 이상을 선택해주세요.')

      return
    }

    delUpdaterApi({ updaterIds: selectedRows })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('삭제하였습니다.')
          setSelectedRows([])
          handleDelClose()
          refetch()
        }
      })
      .catch((err) => alert(err.data.message))
  }

  const handleOpen = (state: boolean, id?: number) => {
    if (state && updaterList.count > 1) {
      alert('등록 갯수를 초과하였습니다.')

      return
    }
    setOpen(true)
    setIsNew(state)
    if (id) {
      setUpdaterId(id)
    }
  }
  const handleDelOpen = () => setDelOpen(true)
  const handleClose = () => setOpen(false)
  const handleDelClose = () => setDelOpen(false)

  return (
    <UpdatePageView
      data={updaterList.data}
      open={open}
      isNew={isNew}
      handleClose={handleClose}
      handleOpen={handleOpen}
      page={page}
      setPage={setPage}
      count={updaterList.count}
      handleRefetch={refetch}
      updaterId={updaterId}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      delContent={delContent}
      delOpen={delOpen}
      handleDelClose={handleDelClose}
      handleDelOpen={handleDelOpen}
    />
  )
}

export default UpdatePage
