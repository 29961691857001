// ** Mui Imports
import {
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material'

// ** Type Imports
import { Model } from '../../../types'

// ** Component Imports
import SelectModal from '../../../components/modal/select-modal'

// ** Type Imports
import type { FaqParams } from '@/types/faq'

interface Props {
  state: boolean
  isNew: boolean
  faq: FaqParams
  setFaq: any
  modelData: Model[]
  open: boolean
  editOpen: boolean
  regContent: () => void
  closeEvent: () => void
  modContent: () => void
  handleOpen: () => void
  handleClose: () => void
  handleEditOpen: () => void
  handleEditClose: () => void
}

const FaqModalView = ({
  state,
  closeEvent,
  isNew,
  faq,
  setFaq,
  modelData,
  regContent,
  modContent,
  handleClose,
  handleOpen,
  handleEditClose,
  handleEditOpen,
  editOpen,
  open,
}: Props) => {
  return (
    <Dialog
      maxWidth="xl"
      open={state}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEvent()
        }
      }}
      fullWidth
    >
      <DialogTitle>FAQ {isNew ? '등록' : '수정'}</DialogTitle>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              공개 여부
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              onChange={setFaq}
              value={faq.exposeYn}
              name="exposeYn"
            >
              <FormControlLabel value="Y" control={<Radio />} label="공개" />
              <FormControlLabel value="N" control={<Radio />} label="비공개" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="model">모델</InputLabel>
            <Select
              labelId="model"
              label="모델"
              defaultValue=""
              value={String(faq.modelId)}
              name="modelId"
              onChange={setFaq}
            >
              <MenuItem value="">전체</MenuItem>
              {modelData.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="제목을 입력하세요."
            label="제목"
            onChange={setFaq}
            name="title"
            value={faq.title}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="내용"
            placeholder="내용을 입력하세요."
            multiline
            onChange={setFaq}
            value={faq.text}
            name="text"
            rows={30}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2}>
          <Button
            fullWidth
            variant="contained"
            color="error"
            onClick={closeEvent}
          >
            취소
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            fullWidth
            variant="contained"
            onClick={isNew ? handleOpen : handleEditOpen}
          >
            {isNew ? '저장' : '수정'}
          </Button>
        </Grid>
        <Grid item xs={4} />
      </Grid>
      {open && (
        <SelectModal
          state={open}
          closeEvent={handleClose}
          event={regContent}
          message="정말 저장하시겠습니까?"
          one="저장"
          two="닫기"
        />
      )}
      {editOpen && (
        <SelectModal
          state={editOpen}
          closeEvent={handleEditClose}
          event={modContent}
          message="정말 수정하시겠습니까?"
          one="수정"
          two="닫기"
        />
      )}
    </Dialog>
  )
}

export default FaqModalView
