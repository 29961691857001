// ** Mui Imports
import { Box, Card, Button, Typography, Grid, Stack } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'

// ** Other Imports
import { Admin } from '@/types'
import dayjs from 'dayjs'

// ** Component Imports
import AdminModal from './admin-page-modal'
import SelectModal from '../../components/modal/select-modal'

const defaultColumns: GridColumns = [
  {
    flex: 0.15,
    field: 'id',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '번호',
    renderCell: ({ row }: { row: Admin }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.id}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'grade',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '등급',
    renderCell: ({ row }: { row: Admin }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.grade}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'username',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '아이디',
    renderCell: ({ row }: { row: Admin }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.username}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'date',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '등록일',
    renderCell: ({ row }: { row: Admin }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {dayjs(row.regDate).format('YYYY.MM.DD HH:mm:ss')}
        </Typography>
      </Box>
    ),
  },
]

interface Props {
  data: Admin[]
  open: boolean
  count: number
  page: number
  isNew: boolean
  selectedRows: number[]
  delOpen: boolean
  handleDelOpen: () => void
  handleDelClose: () => void
  setPage: (e: number) => void
  handleOpen: (state: boolean) => void
  handleRefetch: () => void
  handleClose: () => void
  delContent: () => void
  setSelectedRows: (e: any) => void
}

const AdminPageView = ({
  data,
  open,
  handleClose,
  handleOpen,
  isNew,
  page,
  setPage,
  count,
  handleRefetch,
  selectedRows,
  setSelectedRows,
  delContent,
  delOpen,
  handleDelClose,
  handleDelOpen,
}: Props) => {
  const columns: any = [
    ...defaultColumns,
    {
      flex: 0.15,
      field: 'btn',
      minWidth: 30,
      headerAlign: 'center',
      headerName: '수정',
      renderCell: ({ row }: { row: Admin }) => (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button onClick={() => handleOpen(false)}>수정</Button>
        </Box>
      ),
    },
  ]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={4}>
              <Typography variant="h4">관리자 등록 및 관리</Typography>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleOpen(true)}
              >
                등록
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={handleDelOpen}
              >
                선택 삭제
              </Button>
            </Grid>
          </Grid>
          <DataGrid
            columns={columns}
            rows={data}
            rowCount={count}
            autoHeight
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            disableColumnMenu
            checkboxSelection
            onSelectionModelChange={(row) => setSelectedRows(row)}
            selectionModel={selectedRows}
            pagination
            page={page}
            paginationMode="server"
            pageSize={10}
            onPageChange={(newPage) => {
              setPage(newPage)
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  등록된 정보가 없습니다.
                </Stack>
              ),
            }}
          />
        </Card>
      </Grid>
      {open && (
        <AdminModal
          state={open}
          closeEvent={handleClose}
          isNew={isNew}
          handleRefetch={handleRefetch}
        />
      )}
      {delOpen && (
        <SelectModal
          state={delOpen}
          closeEvent={handleDelClose}
          event={delContent}
          message="정말로 삭제하시겠습니까?"
          one="확인"
          two="닫기"
        />
      )}
    </Grid>
  )
}

export default AdminPageView
