import { useLazyFindPushQuery } from '../../../services'
import DetailModalView from './detail-modal'
import { useEffect, useState } from 'react'
import { PushLog } from '@/types'

interface Props {
  state: boolean
  pushLogId: number
  closeEvent(): void
}

const DetailModal = ({ state, closeEvent, pushLogId }: Props) => {
  const [pushLog, setPushLog] = useState<PushLog>({
    id: 0,
    title: '',
    regDate: new Date(),
    pushType: '',
  })
  const [findPushLogApi] = useLazyFindPushQuery()

  useEffect(() => {
    findPushLogApi(pushLogId)
      .unwrap()
      .then((res) => {
        setPushLog(res.data)
      })
  }, [state])

  return (
    <DetailModalView state={state} closeEvent={closeEvent} pushLog={pushLog} />
  )
}

export default DetailModal
