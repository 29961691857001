import { api } from '../api'

export const updaterApi = api
  .enhanceEndpoints({
    addTagTypes: ['Updater'],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      findUpdaterList: builder.query({
        query: (args) => ({
          url: '/updater',
          params: args,
        }),
      }),
      findUpdater: builder.query({
        query: (id) => ({
          url: `/updater/${id}`,
        }),
      }),
      saveUpdater: builder.mutation({
        query: (args) => ({
          url: '/updater',
          body: args,
          method: 'POST',
        }),
      }),
      updateUpdater: builder.mutation({
        query: (args) => ({
          url: '/updater',
          body: args,
          method: 'PUT',
        }),
      }),
      deleteUpdater: builder.mutation({
        query: (args) => ({
          url: '/updater/delete',
          body: args,
          method: 'POST',
        }),
      }),
    }),
  })

export const {
  useFindUpdaterListQuery,
  useSaveUpdaterMutation,
  useLazyFindUpdaterQuery,
  useUpdateUpdaterMutation,
  useDeleteUpdaterMutation,
} = updaterApi
