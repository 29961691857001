// ** React Imports
import { useEffect, useMemo, useState } from 'react'

// ** Component Imports
import PushPageView from './push-page'

// ** Redux Imports
import {
  useFindModelListNoPaginationQuery,
  useLazyFindUserListAllQuery,
  useLazyFindUserListQuery,
  usePushAppMutation,
} from '../../services'

// ** Type Imports
import { User } from '@/types'
import type { PushUserParams } from '@/types/push'

// ** Utils Imports
import { isUndefined } from 'lodash'
import useInput from '../../hooks/useInput'

const PushPage = () => {
  const {
    data: query,
    handler: setQuery,
    setData,
  } = useInput<PushUserParams>({
    email: '',
    type: '',
    socialType: '',
    lang: '',
    osType: '',
    modelId: 0,
  })

  const {
    data: push,
    handler: setPush,
    setData: setPushData,
  } = useInput({
    title: '',
    text: '',
  })

  const [page, setPage] = useState<number>(0)
  const [page2, setPage2] = useState<number>(0)
  const [reRenderSwitch, setReRenderSwitch] = useState<boolean>(false)

  const [open, setOpen] = useState<boolean>(false)
  const [cancelOpen, setCancelOpen] = useState<boolean>(false)

  const [userList, setUserList] = useState<User[]>([])
  const [selectUserList, setSelectUserList] = useState<User[]>([])
  const [count, setCount] = useState<number>(0)

  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [selectedRows2, setSelectedRows2] = useState<number[]>([])

  const args = () => {
    const arg: {
      page: number
      pageSize: number
      email?: string
      socialType?: string
      lang?: string
      osType?: string
      modelId?: number
      type: string
    } = {
      page,
      pageSize: 10,
      type: 'ACTIVE',
    }
    if (query.type !== '') arg.type = query.type
    if (query.email !== '') arg.email = query.email
    if (query.socialType !== '') arg.socialType = query.socialType
    if (query.lang !== '') arg.lang = query.lang
    if (query.osType !== '') arg.osType = query.osType
    if (query.modelId !== 0) arg.modelId = query.modelId

    return arg
  }

  const { data: modelList } = useFindModelListNoPaginationQuery('')

  const modelData = useMemo(
    () => (isUndefined(modelList) ? [] : modelList.data.data),
    [modelList],
  )

  const [findUserApi] = useLazyFindUserListQuery()
  const [findUserAllApi] = useLazyFindUserListAllQuery()
  const [pushApi] = usePushAppMutation()

  const handleRefetch = () => setReRenderSwitch(true)
  const handleInitQuery = () => {
    setData({
      email: '',
      socialType: '',
      lang: '',
      osType: '',
      type: '',
      modelId: 0,
    })
    handleRefetch()
  }

  const handleOpen = () => setOpen(true)
  const handleCancelOpen = () => setCancelOpen(true)
  const handleClose = () => setOpen(false)
  const handleCancelClose = () => setCancelOpen(false)

  const handleAddSelectUserList = () => {
    const arr: User[] = userList
      .filter((item) => selectedRows.includes(item.id))
      .filter((item) => !selectUserList.includes(item))

    setSelectUserList((cur) => [...cur, ...arr])

    setSelectedRows([])
  }
  const handleRemoveSelectUserList = () => {
    setSelectUserList((cur) =>
      cur.filter((item) => !selectedRows2.includes(item.id)),
    )

    setSelectedRows2([])
  }

  const handleInit = () => {
    handleClose()
    handleCancelClose()
    setSelectedRows2([])
    setSelectedRows([])
    setSelectUserList([])
    setData({
      email: '',
      type: '',
      socialType: '',
      lang: '',
      osType: '',
      modelId: 0,
    })
    setPushData({
      title: '',
      text: '',
    })
  }

  const handleSelectAll = () => {
    findUserAllApi(args())
      .unwrap()
      .then((res) => {
        setSelectUserList(res.data.data)

        setSelectedRows([])
      })
  }

  const handleUnSelectAll = () => {
    setSelectUserList([])
    setSelectedRows2([])
    setPage2(0)
  }

  const handlePushMail = () => {
    if (selectUserList.length < 1) {
      alert('1명 이상의 유저를 선택해주세요.')

      return
    }

    if (push.title === '') {
      alert('제목을 입력하세요.')

      return
    }

    if (push.text === '') {
      alert('내용을 입력하세요.')

      return
    }

    pushApi({
      ...push,
      userTokenList: selectUserList.map((item) => item.pushToken),
    })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('푸시 발송에 성공하였습니다.')
          handleInit()
        }
      })
      .catch((err) => alert(err.data.message))
  }

  useEffect(() => {
    if (reRenderSwitch) {
      setReRenderSwitch(false)
    }

    findUserApi(args())
      .unwrap()
      .then((res) => {
        setUserList(res.data.data)
        setCount(res.data.count)
      })
  }, [reRenderSwitch, page])

  return (
    <PushPageView
      data={userList}
      selectData={selectUserList}
      handleRefetch={handleRefetch}
      page={page}
      setPage={setPage}
      count={count}
      push={push}
      setPush={setPush}
      query={query}
      setQuery={setQuery}
      modelData={modelData}
      handleInitQuery={handleInitQuery}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      selectedRows2={selectedRows2}
      setSelectedRows2={setSelectedRows2}
      handleAddSelectUserList={handleAddSelectUserList}
      handleRemoveSelectUserList={handleRemoveSelectUserList}
      handlePushMail={handlePushMail}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleCancelOpen={handleCancelOpen}
      handleCancelClose={handleCancelClose}
      cancelOpen={cancelOpen}
      handleInit={handleInit}
      open={open}
      page2={page2}
      setPage2={setPage2}
      handleSelectAll={handleSelectAll}
      handleUnSelectAll={handleUnSelectAll}
    />
  )
}

export default PushPage
