// ** React Imports
import { useEffect, useState } from 'react'

// ** Component Imports
import PolicyPageView from './policy-page'

// ** Redux Imports
import {
  useLazyFindPolicyQuery,
  useUpdatePolicyMutation,
} from '../../services/policy'

// ** Util Imports
import useInput from '../../hooks/useInput'

const PolicyPage = () => {
  const {
    data: policy,
    handler: setPolicy,
    setData,
  } = useInput({
    id: 0,
    title: '',
    text: '',
    type: '',
  })
  const [value, setValue] = useState<number>(0)
  const [reRenderSwitch, setReRenderSwitch] = useState<boolean>(false)

  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const handleRefetch = () => setReRenderSwitch(true)

  const [findPolicy] = useLazyFindPolicyQuery()
  const [updatePolicy] = useUpdatePolicyMutation()

  const getType = (num: number) => {
    switch (num) {
      case 0:
        return { type: 'PERSONAL_INFO' }

      case 1:
        return { type: 'MARKETING' }

      default:
        break
    }
  }

  const modContent = () => {
    if (policy.text === '') {
      alert('내용을 입력해주세요.')

      return
    }

    updatePolicy({ policyId: policy.id, text: policy.text })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('정책을 변경합니다.')
          handleClose()
          handleRefetch()
        }
      })
      .catch((err) => {
        alert(err.data.message)
        handleClose()
      })
  }

  useEffect(() => {
    if (reRenderSwitch) {
      setReRenderSwitch(false)
    }

    findPolicy(getType(value))
      .unwrap()
      .then((res) => {
        setData(res.data)
      })
  }, [value, reRenderSwitch])

  return (
    <PolicyPageView
      value={value}
      handleChange={handleChange}
      policyData={policy}
      setPolicy={setPolicy}
      modContent={modContent}
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
    />
  )
}

export default PolicyPage
