import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dates } from '@/types'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback } from 'react'
import { Box } from '@mui/system'

interface PropsType {
  dates: Dates
  setDates: (e: any) => void
}

const DateRangePicker = ({ dates, setDates }: PropsType) => {
  const onChange = useCallback(
    (e: Dayjs | null, type: 'startDate' | 'endDate') => {
      if (!e) return
      if (type === 'startDate') {
        if (dayjs(e) > dates.endDate) {
          alert('시작 날짜가 마지막 날짜를 넘어갈 수 없습니다.')

          return
        }

        setDates((cur: any) => ({ ...cur, startDate: dayjs(e) }))
      }

      if (type === 'endDate') {
        if (dayjs(e) <= dates.startDate) {
          alert('시작 날짜가 마지막 날짜를 넘어갈 수 없습니다.')

          return
        }

        setDates((cur: any) => ({ ...cur, endDate: dayjs(e) }))
      }
    },
    [setDates, dates],
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="시작 날짜"
        format="YYYY.MM.DD"
        maxDate={dates.endDate}
        value={dates.startDate}
        onChange={(e) => onChange(e, 'startDate')}
        sx={{ mr: 2 }}
      />
      <DatePicker
        label="마감 날짜"
        format="YYYY.MM.DD"
        value={dates.endDate}
        minDate={dates.startDate}
        onChange={(e) => onChange(e, 'endDate')}
      />
    </LocalizationProvider>
  )
}

export default DateRangePicker
