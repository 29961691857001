// ** Mui Imports
import { Dialog, DialogTitle, Grid, TextField, Button } from '@mui/material'

// ** Component Imports
import SelectModal from '../../../components/modal/select-modal'

interface Props {
  state: boolean
  isNew: boolean
  open: boolean
  model: { name: string }
  setModel: (e: any) => void
  regContent: () => void
  modContent: () => void
  closeEvent: () => void
  handleOpen: () => void
  handleClose: () => void
}

const ModelPageModalView = ({
  state,
  closeEvent,
  isNew,
  modContent,
  regContent,
  model,
  setModel,
  handleOpen,
  handleClose,
  open,
}: Props) => {
  return (
    <Dialog
      maxWidth="sm"
      open={state}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEvent()
        }
      }}
      fullWidth
    >
      <DialogTitle>모델 {isNew ? '등록' : '수정'}</DialogTitle>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <TextField
            label="모델명"
            placeholder="모델명를 입력하세요"
            fullWidth
            value={model.name}
            onChange={setModel}
            name="name"
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2}>
          <Button variant="contained" color="error" onClick={closeEvent}>
            취소
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={isNew ? regContent : handleOpen}>
            {isNew ? '저장' : '수정'}
          </Button>
        </Grid>
        <Grid item xs={4} />
        {open && (
          <SelectModal
            state={open}
            closeEvent={handleClose}
            message="정말로 수정하시겠습니까?"
            one="수정"
            two="취소"
            event={modContent}
          />
        )}
      </Grid>
    </Dialog>
  )
}

export default ModelPageModalView
