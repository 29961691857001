// ** Mui Imports
import {
  Box,
  Card,
  Button,
  Typography,
  Grid,
  Stack,
  TextField,
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'

// ** Other Imports
import { AdminHistory, Dates } from '@/types'
import dayjs from 'dayjs'

// ** Component Imports
import AdminHistoryModal from './admin-history-page-modal'
import SelectModal from '../../components/modal/select-modal'
import DateRangePicker from '../../components/datepicker'

const defaultColumns: GridColumns = [
  {
    flex: 0.15,
    field: 'id',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '번호',
    renderCell: ({ row }: { row: AdminHistory }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.id}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'admin',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '관리자',
    renderCell: ({ row }: { row: AdminHistory }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.admin}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'title',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '제목',
    renderCell: ({ row }: { row: AdminHistory }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.title}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'date',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '등록일',
    renderCell: ({ row }: { row: AdminHistory }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {dayjs(row.regDate).format('YYYY.MM.DD HH:mm:ss')}
        </Typography>
      </Box>
    ),
  },
]

interface Props {
  data: AdminHistory[]
  count: number
  page: number
  open: boolean
  isNew: boolean
  query: { title: string; admin: string }
  dates: Dates
  historyId: number
  selectedRows: number[]
  delOpen: boolean
  handleDelClose: () => void
  handleDelOpen: () => void
  delContent: () => void
  setPage: (e: number) => void
  setQuery: (e: any) => void
  setDates: (e: any) => void
  handleOpen: (state: boolean, id?: number) => void
  handleClose: () => void
  handleRefetch: () => void
  handleQueryInit: () => void
  setSelectedRows: (e: any) => void
}

const AdminHistoryPageView = ({
  data,
  open,
  handleClose,
  handleOpen,
  isNew,
  dates,
  setDates,
  query,
  setQuery,
  setPage,
  page,
  handleQueryInit,
  handleRefetch,
  count,
  historyId,
  selectedRows,
  setSelectedRows,
  delContent,
  delOpen,
  handleDelClose,
  handleDelOpen,
}: Props) => {
  const columns: any = [
    ...defaultColumns,
    {
      flex: 0.15,
      field: 'btn',
      minWidth: 30,
      headerAlign: 'center',
      headerName: '수정',
      renderCell: ({ row }: { row: AdminHistory }) => (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button onClick={() => handleOpen(false, row.id)}>수정</Button>
        </Box>
      ),
    },
  ]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                기간 조회
              </Typography>
              <DateRangePicker dates={dates} setDates={setDates} />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                조건 검색
              </Typography>
              <TextField
                fullWidth
                label="관리자명"
                value={query.admin}
                onChange={setQuery}
                name="admin"
              />
            </Grid>
            <Grid item xs={2} sx={{ mt: 6 }}>
              <TextField
                fullWidth
                label="제목"
                value={query.title}
                onChange={setQuery}
                name="title"
              />
            </Grid>
            <Grid item xs={1} sx={{ mt: 6 }}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={handleRefetch}
              >
                조회
              </Button>
            </Grid>
            <Grid item xs={1} sx={{ mt: 6 }}>
              <Button
                variant="contained"
                color="error"
                size="large"
                fullWidth
                onClick={handleQueryInit}
              >
                초기화
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={4}>
              <Typography variant="h4">운영 리스트</Typography>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleOpen(true)}
              >
                등록
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={handleDelOpen}
              >
                선택 삭제
              </Button>
            </Grid>
          </Grid>
          <DataGrid
            columns={columns}
            rows={data}
            rowCount={count}
            autoHeight
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            disableColumnMenu
            checkboxSelection
            onSelectionModelChange={(row) => setSelectedRows(row)}
            selectionModel={selectedRows}
            pagination
            page={page}
            paginationMode="server"
            pageSize={10}
            onPageChange={(newPage) => {
              setPage(newPage)
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  등록된 정보가 없습니다.
                </Stack>
              ),
            }}
          />
        </Card>
      </Grid>
      {open && (
        <AdminHistoryModal
          state={open}
          closeEvent={handleClose}
          isNew={isNew}
          historyId={historyId}
          handleRefetch={handleRefetch}
        />
      )}
      {delOpen && (
        <SelectModal
          state={delOpen}
          closeEvent={handleDelClose}
          event={delContent}
          message="정말로 삭제하시겠습니까?"
          one="확인"
          two="닫기"
        />
      )}
    </Grid>
  )
}

export default AdminHistoryPageView
