import { api } from '../api'

export const adminApi = api
  .enhanceEndpoints({
    addTagTypes: ['Admin'],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      findAdminList: builder.query({
        query: (args) => ({
          url: '/admin',
          params: args,
        }),
      }),
      saveAdmin: builder.mutation({
        query: (args) => ({
          url: '/auth/admin',
          body: args,
          method: 'POST',
        }),
      }),
      deleteAdmin: builder.mutation({
        query: (args) => ({
          url: '/admin/delete',
          body: args,
          method: 'POST',
        }),
      }),
    }),
  })

export const {
  useFindAdminListQuery,
  useSaveAdminMutation,
  useDeleteAdminMutation,
} = adminApi
