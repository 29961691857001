// ** React Imports
import { useEffect, useState } from 'react'

// ** Component Imports
import ModelPageModalView from './model-page-modal'

// ** Redux Imports
import {
  useLazyFindModelQuery,
  useSaveModelMutation,
  useUpdateModelMutation,
} from '../../../services'

// ** Util Imports
import useInput from '../../../hooks/useInput'

// ** Type Imports
import type { ModelParams } from '@/types/model'

interface Props {
  state: boolean
  isNew: boolean
  modelId: number
  closeEvent: () => void
  handleRefetch: () => void
}

const ModelPageModal = ({
  state,
  closeEvent,
  modelId,
  isNew,
  handleRefetch,
}: Props) => {
  const {
    data: model,
    handler: setModel,
    setData,
  } = useInput<ModelParams>({ name: '' })

  const [open, setOpen] = useState<boolean>(false)

  const [saveModelApi] = useSaveModelMutation()
  const [updateModelApi] = useUpdateModelMutation()
  const [findModelApi] = useLazyFindModelQuery()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const regContent = () => {
    if (model.name === '') {
      alert('모델명을 입력하세요.')

      return
    }

    saveModelApi(model)
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('모델을 등록하였습니다.')
          handleRefetch()
          closeEvent()
        }
      })
      .catch((err) => alert(err.data.message))
  }

  const modContent = () => {
    if (model.name === '') {
      alert('모델명을 입력하세요.')
      handleClose()

      return
    }

    updateModelApi({ ...model, modelId })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('모델을 수정하였습니다.')
          handleRefetch()
          handleClose()
          closeEvent()
        }
      })
      .catch((err) => {
        alert(err.data.message)
        handleClose()
      })
  }

  useEffect(() => {
    if (!isNew)
      findModelApi(modelId)
        .unwrap()
        .then((res) => {
          setData(res.data)
        })
  }, [state])

  return (
    <ModelPageModalView
      state={state}
      closeEvent={closeEvent}
      isNew={isNew}
      modContent={modContent}
      regContent={regContent}
      model={model}
      setModel={setModel}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
    />
  )
}

export default ModelPageModal
