// ** React Imports
import { useEffect, useMemo, useState } from 'react'

// ** Component Imports
import AppVersionModalView from './firmware-page-modal'

// ** Util Imports
import useInput from '../../../hooks/useInput'
import { isUndefined } from 'lodash'

// ** Redux Imports
import {
  useFindModelListNoPaginationQuery,
  useLazyFindFirmwareQuery,
  useSaveFirmwareMutation,
  useUpdateFirmwareMutation,
} from '../../../services'

// ** Type Imports
import type { FirmwareParmas } from '@/types/firmware'

interface Props {
  state: boolean
  isNew: boolean
  firmwareId: number
  handleRefetch: () => void
  closeEvent(): void
}

const AppVersionModal = ({
  state,
  closeEvent,
  isNew,
  handleRefetch,
  firmwareId,
}: Props) => {
  const {
    data: firmware,
    handler: setFirmware,
    setData,
  } = useInput<FirmwareParmas>({
    modelId: '',
    filePath: '',
    fileName: '',
    version: '',
    name: '',
  })

  const [open, setOpen] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)

  const [saveFirmwareApi] = useSaveFirmwareMutation()
  const [updateFirmwareApi] = useUpdateFirmwareMutation()
  const [findFirmwareApi] = useLazyFindFirmwareQuery()

  const { data: modelList } = useFindModelListNoPaginationQuery('')

  const modelData = useMemo(
    () => (isUndefined(modelList) ? [] : modelList.data.data),
    [modelList],
  )

  const handleOpen = () => setOpen(true)
  const handleEditOpen = () => setEditOpen(true)
  const handleClose = () => setOpen(false)
  const handleEditClose = () => setEditOpen(false)

  const regContent = () => {
    if (firmware.modelId === '') {
      alert('모델을 선택하세요.')

      return
    }

    if (firmware.filePath === '') {
      alert('파일을 선택하세요.')

      return
    }

    if (firmware.version === '') {
      alert('버전을 입력하세요.')

      return
    }

    if (firmware.name === '') {
      alert('이름을 입력하세요.')

      return
    }

    saveFirmwareApi(firmware)
      .unwrap()
      .then((res) => {
        alert('펌웨어를 생성하였습니다.')
        setData({
          modelId: '',
          filePath: '',
          version: '',
          name: '',
          fileName: '',
        })
        handleClose()
        handleRefetch()
        closeEvent()
      })
      .catch((err) => alert(err.data.message))
  }

  const modContent = () => {
    if (firmware.modelId === '') {
      alert('모델을 선택하세요.')

      return
    }

    if (firmware.filePath === '') {
      alert('파일을 선택하세요.')

      return
    }

    if (firmware.version === '') {
      alert('버전을 입력하세요.')

      return
    }

    if (firmware.name === '') {
      alert('이름을 입력하세요.')

      return
    }

    updateFirmwareApi({ ...firmware, firmwareId: firmware.id })
      .unwrap()
      .then((res) => {
        alert('펌웨어를 수정하였습니다.')
        setData({
          modelId: '',
          filePath: '',
          version: '',
          name: '',
          fileName: '',
        })
        handleClose()
        handleRefetch()
        closeEvent()
      })
      .catch((err) => alert(err.data.message))
  }

  const setFilePath = (filePath: string) => {
    setData((cur: any) => ({ ...cur, filePath }))
  }

  const setFileName = (fileName: string) => {
    setData((cur: any) => ({ ...cur, fileName }))
  }

  useEffect(() => {
    if (!isNew) {
      findFirmwareApi(firmwareId)
        .unwrap()
        .then((res) => {
          setData({ ...res.data, modelId: res.data.model.id })
        })
    }
  }, [state])

  return (
    <AppVersionModalView
      state={state}
      closeEvent={closeEvent}
      isNew={isNew}
      firmware={firmware}
      setFirmware={setFirmware}
      regContent={regContent}
      modContent={modContent}
      modelData={modelData}
      setData={setData}
      setFilePath={setFilePath}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      handleEditClose={handleEditClose}
      handleEditOpen={handleEditOpen}
      editOpen={editOpen}
      setFileName={setFileName}
    />
  )
}

export default AppVersionModal
