// ** React Imports
import { useEffect, useState, useMemo } from 'react'

// ** Mui Imports
import { SelectChangeEvent } from '@mui/material'

// ** Componenet Imports
import UserChartPageView from './user-chart-page'

// ** Type Imports
import { Dates, UserChart } from '@/types'

// ** Redux Imports
import {
  useFindModelListNoPaginationQuery,
  useLazyFindUserStatQuery,
  useSaveUserManualMutation,
} from '../../services'

// ** Utils Imports
import dayjs from 'dayjs'
import useInput from '../../hooks/useInput'
import { isUndefined } from 'lodash'
import { getDayOneMonth, getDayOne3Month, getYearList } from '../../utils/date'
import excelDownload from '../../utils/excel'

const UserChartPage = () => {
  const {
    data: query,
    handler: setQuery,
    setData: setQueryData,
  } = useInput({
    type: '',
    socialType: '',
    lang: '',
    osType: '',
    modelId: '',
  })
  const [dates, setDates] = useState<Dates>({
    startDate: dayjs().subtract(1, 'week'),
    endDate: dayjs(),
  })
  const [day, setDay] = useState<number>(1)
  const [year, setYear] = useState<number>(getYearList()[0])

  const [data, setData] = useState<UserChart[]>([])

  const [reRenderSwitch, setReRenderSwitch] = useState<boolean>(false)

  const [findUserStatsApi] = useLazyFindUserStatQuery()
  const [saveManage] = useSaveUserManualMutation()

  const { data: modelList } = useFindModelListNoPaginationQuery('')

  const modelData = useMemo(
    () => (isUndefined(modelList) ? [] : modelList.data.data),
    [modelList],
  )

  const args = () => {
    const arg: {
      type?: string
      socialType?: string
      lang?: string
      osType?: string
      modelId?: string
      startDate: string
      endDate: string
    } = {
      startDate: dates.startDate.format('YYYY-MM-DD'),
      endDate: dates.endDate.format('YYYY-MM-DD'),
    }

    if (day === 30 || day === 90) {
      arg.startDate = year + '-01-01'
      arg.endDate = year + '-12-31'
    }

    if (query.type !== '') arg.type = query.type
    if (query.socialType !== '') arg.socialType = query.socialType
    if (query.lang !== '') arg.lang = query.lang
    if (query.osType !== '') arg.osType = query.osType
    if (query.modelId !== '') arg.modelId = query.modelId

    return arg
  }

  const handleDay = (e: SelectChangeEvent<number>) => {
    setDay(Number(e.target.value))
  }

  const handleYear = (e: SelectChangeEvent<number>) => {
    setYear(Number(e.target.value))
  }

  const handleRefetch = () => setReRenderSwitch(true)
  const handleInitQuery = () => {
    setQueryData({
      type: '',
      socialType: '',
      lang: '',
      osType: '',
      modelId: '',
    })
    setDay(1)
    handleRefetch()
  }

  const handleExcelDownload = () => {
    excelDownload({
      data: chartData.map((item: UserChart) => ({
        날짜: item.date,
        '가입 회원수': item.count,
      })),
      filename: dayjs().format('YYYY-MM-DD') + ' 고객 현황',
      colsSize: [150, 150],
      colName: ['날짜', '가입 회원수'],
    })

    saveManage({ pageName: '고객 현황', type: 'EXCEL' })
      .unwrap()
      .then((res) => {})
  }

  const chartData: any = useMemo(() => {
    switch (day) {
      case 1:
        return data

      case 7:
        return data
          .map((item, index) => {
            if (index === 0 || index % 7 === 0) {
              const count = data
                .slice(index, index + 7)
                .reduce((cur, ocr) => cur + ocr.count, 0)

              return { date: item.date, count }
            }

            return null
          })
          .filter((item) => item !== null)

      case 30:
        return data
          .map((item, index) => {
            const itemDay = dayjs(item.date).get('date')

            if (itemDay !== 1) {
              return null
            }

            const day2 = getDayOneMonth(
              dayjs(item.date).get('year'),
              dayjs(item.date).get('month') + 1,
            )

            const count = data
              .slice(index, index + day2)
              .reduce((cur, ocr) => cur + ocr.count, 0)

            return { date: dayjs(item.date).format('YYYY-MM'), count }
          })
          .filter((item) => item !== null)

      case 90:
        return data
          .map((item, index) => {
            const itemDay = dayjs(item.date).get('date')
            const itemMonth = dayjs(item.date).get('month') + 1

            if (itemDay !== 1) {
              return null
            }

            const numArr = [1, 4, 7, 10]

            if (!numArr.includes(itemMonth)) {
              return null
            }

            const day3 = getDayOne3Month(
              dayjs(item.date).get('year'),
              dayjs(item.date).get('month') + 1,
            )

            const count = data
              .slice(index, index + day3 - 1)
              .reduce((cur, ocr) => cur + ocr.count, 0)

            return { date: `${numArr.indexOf(itemMonth) + 1}분기`, count }
          })
          .filter((item) => item !== null)

      default:
        return []
    }
  }, [day, data])

  useEffect(() => {
    if (reRenderSwitch) {
      setReRenderSwitch(false)
    }

    saveManage({ pageName: '고객 현황', type: 'READ' })
      .unwrap()
      .then((res) => {})

    findUserStatsApi(args())
      .unwrap()
      .then((res) => {
        setData(res.data)
      })
  }, [reRenderSwitch])

  useEffect(() => {
    if (day === 1 || day === 7) return

    saveManage({ pageName: '고객 현황', type: 'READ' })
      .unwrap()
      .then((res) => {})

    findUserStatsApi(args())
      .unwrap()
      .then((res) => {
        setData(res.data)
      })
  }, [day])

  return (
    <UserChartPageView
      day={day}
      handleDay={handleDay}
      setDates={setDates}
      dates={dates}
      query={query}
      setQuery={setQuery}
      modelData={modelData}
      data={chartData}
      handleRefetch={handleRefetch}
      handleInitQuery={handleInitQuery}
      handleExcelDownload={handleExcelDownload}
      year={year}
      handleYear={handleYear}
    />
  )
}

export default UserChartPage
