// ** Mui Imports
import { Dialog, DialogTitle, Grid, TextField, Button } from '@mui/material'

// ** Component Imports
import SelectModal from '../../../components/modal/select-modal'

interface Props {
  state: boolean
  isNew: boolean
  open: boolean
  admin: { username: string; password: string; passwordC: string }
  closeEvent: () => void
  handleOpen: () => void
  handleClose: () => void
  regContent: () => void
  setAdmin: (e: any) => void
}

const AdminModalView = ({
  state,
  closeEvent,
  isNew,
  admin,
  setAdmin,
  handleClose,
  handleOpen,
  regContent,
  open,
}: Props) => {
  return (
    <Dialog
      maxWidth="sm"
      open={state}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEvent()
        }
      }}
      fullWidth
    >
      <DialogTitle>관리자 {isNew ? '등록' : '수정'}</DialogTitle>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <TextField
            label="아이디"
            placeholder="아이디를 입력하세요"
            fullWidth
            value={admin.username}
            onChange={setAdmin}
            name="username"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="비밀번호"
            placeholder="비밀번호를 입력하세요"
            fullWidth
            value={admin.password}
            onChange={setAdmin}
            name="password"
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="비밀번호 확인"
            type="password"
            placeholder="비밀번호를 한 번 더 입력하세요"
            value={admin.passwordC}
            onChange={setAdmin}
            name="passwordC"
            fullWidth
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2}>
          <Button variant="contained" color="error" onClick={closeEvent}>
            취소
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={handleOpen}>
            저장
          </Button>
        </Grid>
        <Grid item xs={4} />
      </Grid>
      {open && (
        <SelectModal
          state={open}
          closeEvent={handleClose}
          event={regContent}
          message="정말로 등록하시겠습니까?"
          one="등록"
          two="취소"
        />
      )}
    </Dialog>
  )
}

export default AdminModalView
