// ** React Imports
import { useEffect, useState } from 'react'

// ** Component Imports
import UpdateModalView from './update-page-modal'

// ** Util Imports
import useInput from '../../../hooks/useInput'

// ** Redux Imports
import {
  useLazyFindUpdaterQuery,
  useSaveUpdaterMutation,
  useUpdateUpdaterMutation,
} from '../../../services'

// ** Type Imports
import type { UpdaterParams } from '@/types/updater'

interface Props {
  state: boolean
  isNew: boolean
  updaterId: number
  handleRefetch: () => void
  closeEvent: () => void
}

const UpdateModal = ({
  state,
  closeEvent,
  updaterId,
  isNew,
  handleRefetch,
}: Props) => {
  const {
    data: updater,
    handler: setUpdater,
    setData,
  } = useInput<UpdaterParams>({
    os: '',
    filePath: '',
    fileName: '',
  })

  const [open, setOpen] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)

  const [saveUpdaterApi] = useSaveUpdaterMutation()
  const [findUpdaterApi] = useLazyFindUpdaterQuery()
  const [updateUpdaterApi] = useUpdateUpdaterMutation()

  const handleOpen = () => setOpen(true)
  const handleEditOpen = () => setEditOpen(true)
  const handleClose = () => setOpen(false)
  const handleEditClose = () => setEditOpen(false)

  const setFilePath = (filePath: string) => {
    setData((cur: any) => ({ ...cur, filePath }))
  }

  const setFileName = (fileName: string) => {
    setData((cur: any) => ({ ...cur, fileName }))
  }

  const regContent = () => {
    if (updater.os === '') {
      alert('OS를 입력해주세요')
      handleClose()

      return
    }

    if (updater.filePath === '') {
      alert('파일을 선택해주세요')
      handleClose()

      return
    }

    saveUpdaterApi(updater)
      .unwrap()
      .then((res) => {
        handleRefetch()
        alert('생성 되었습니다.')
        handleClose()
        setData({ os: '', filePath: '', fileName: '' })
        closeEvent()
      })
      .catch((err) => {
        alert(err.data.message)
        handleClose()
      })
  }

  const modContent = () => {
    if (updater.os === '') {
      alert('OS를 입력해주세요')
      handleEditClose()

      return
    }

    if (updater.filePath === '') {
      alert('파일을 선택해주세요')
      handleEditClose()

      return
    }

    updateUpdaterApi({ ...updater, updaterId })
      .unwrap()
      .then((res) => {
        handleRefetch()
        alert('수정 되었습니다.')
        handleEditClose()
        setData({ os: '', filePath: '', fileName: '' })
        closeEvent()
      })
      .catch((err) => {
        alert(err.data.message)
        handleClose()
      })
  }

  useEffect(() => {
    if (!isNew) {
      findUpdaterApi(updaterId)
        .unwrap()
        .then((res) => {
          setData({ ...res.data })
        })
    }
  }, [state])

  return (
    <UpdateModalView
      state={state}
      closeEvent={closeEvent}
      isNew={isNew}
      updater={updater}
      setUpdater={setUpdater}
      setFilePath={setFilePath}
      regContent={regContent}
      modContent={modContent}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      editOpen={editOpen}
      handleEditClose={handleEditClose}
      handleEditOpen={handleEditOpen}
      setFileName={setFileName}
    />
  )
}

export default UpdateModal
