import { api } from '../api'

export const appVersionApi = api
  .enhanceEndpoints({
    addTagTypes: ['AppVersion'],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      findAppVersionList: builder.query({
        query: (args) => ({
          url: '/app/version',
          params: args,
        }),
      }),
      saveAppVersion: builder.mutation({
        query: (args) => ({
          url: '/app/version',
          body: args,
          method: 'POST',
        }),
      }),
      deleteAppVersion: builder.mutation({
        query: (args) => ({
          url: '/app/version/delete',
          body: args,
          method: 'POST',
        }),
      }),
    }),
  })

export const {
  useLazyFindAppVersionListQuery,
  useSaveAppVersionMutation,
  useDeleteAppVersionMutation,
} = appVersionApi
