// ** React Imports
import { useEffect, useState } from 'react'

// ** Component Imports
import AdminHistoryModalView from './admin-history-page-modal'

// ** Util Imports
import useInput from '../../../hooks/useInput'

// ** Redux Imports
import {
  useLazyFindAdminHistoryQuery,
  useSaveAdminHistoryMutation,
  useUpdateAdminHistoryMutation,
} from '../../../services'

interface Props {
  state: boolean
  isNew: boolean
  historyId: number
  handleRefetch: () => void
  closeEvent: () => void
}

const AdminHistoryModal = ({
  state,
  closeEvent,
  isNew,
  handleRefetch,
  historyId,
}: Props) => {
  const {
    data: history,
    handler: setHistory,
    setData,
  } = useInput({
    admin: '',
    title: '',
    text: '',
  })

  const [open, setOpen] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [cancelOpen, setCancelOpen] = useState<boolean>(false)

  const [historySaveApi] = useSaveAdminHistoryMutation()
  const [historyFindApi] = useLazyFindAdminHistoryQuery()
  const [historyUpdateApi] = useUpdateAdminHistoryMutation()

  const handleOpen = () => setOpen(true)
  const handleEditOpen = () => setEditOpen(true)
  const handleCancelOpen = () => setCancelOpen(true)
  const handleClose = () => setOpen(false)
  const handleEditClose = () => setEditOpen(false)
  const handleCancelClose = () => setCancelOpen(false)

  const regContent = () => {
    if (history.admin === '') {
      alert('관리자명을 입력해주세요.')
      handleClose()

      return
    }

    if (history.title === '') {
      alert('제목을 입력해주세요.')
      handleClose()

      return
    }

    if (history.text === '') {
      alert('내용을 입력해주세요.')
      handleClose()

      return
    }

    historySaveApi(history)
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('생성되었습니다.')
          setData({ admin: '', title: '', text: '' })
          handleClose()
          closeEvent()
          handleRefetch()
        }
      })
  }

  const modContent = () => {
    if (history.admin === '') {
      alert('관리자명을 입력해주세요.')
      handleClose()

      return
    }

    if (history.title === '') {
      alert('제목을 입력해주세요.')
      handleClose()

      return
    }

    if (history.text === '') {
      alert('내용을 입력해주세요.')
      handleClose()

      return
    }

    historyUpdateApi(history)
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('수정되었습니다.')
          setData({ admin: '', title: '', text: '' })
          handleClose()
          closeEvent()
          handleRefetch()
        }
      })
  }

  useEffect(() => {
    if (!isNew) {
      historyFindApi(historyId)
        .unwrap()
        .then((res) => {
          setData(res.data)
        })
    }
  }, [state])

  return (
    <AdminHistoryModalView
      state={state}
      closeEvent={closeEvent}
      isNew={isNew}
      history={history}
      setHistory={setHistory}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      regContent={regContent}
      cancelOpen={cancelOpen}
      handleCancelClose={handleCancelClose}
      handleCancelOpen={handleCancelOpen}
      modContent={modContent}
      editOpen={editOpen}
      handleEditClose={handleEditClose}
      handleEditOpen={handleEditOpen}
    />
  )
}

export default AdminHistoryModal
