// ** Mui Imports
import { Button, Dialog, DialogTitle, Grid, TextField } from '@mui/material'

// ** Util Imports
import dayjs from 'dayjs'

// ** Type Imports
import { PushLog } from '@/types'

interface Props {
  state: boolean
  pushLog: PushLog
  closeEvent(): void
}

const DetailModalView = ({ state, closeEvent, pushLog }: Props) => {
  return (
    <Dialog
      maxWidth="lg"
      open={state}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEvent()
        }
      }}
      fullWidth
    >
      <DialogTitle>
        {pushLog.pushType === 'EMAIL' ? '메일 발송 로그' : '푸시 발송 로그'}
      </DialogTitle>
      <Grid container spacing={3} sx={{ p: 5 }}>
        {pushLog.pushType === 'EMAIL' && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              value={pushLog.sender}
              label="발송 이메일"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled
            value={dayjs(pushLog.regDate).format('YYYY.MM.DD HH:mm:ss')}
            label="전송 일시"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth disabled value={pushLog.title} label="제목" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled
            value={
              pushLog.text ? pushLog.text.replace(/<\/?[^>]+(>|$)/g, '') : ''
            }
            label="내용"
            multiline
            rows={30}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={closeEvent}>
            닫기
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DetailModalView
