// ** Router Imports
import { Route, Routes } from 'react-router-dom'

// ** Component Imports
import LoginPage from './pages/login-page'
import { ProtectRoute, PublicRoute } from './utils/protect-route'
import UserPage from './pages/user-page'
import UserActivePage from './pages/active-page'
import UserHistoryPage from './pages/user-history-page'
import MailPage from './pages/mail-page'
import PushHistoryPage from './pages/push-history-page'
import PolicyPage from './pages/policy-page'
import PushPage from './pages/push-page'
import UserGuidePage from './pages/user-guide-page'
import UserChartPage from './pages/user-chart-page'
import PolicyAppVersionPage from './pages/app-version-page'
import FirmwarePage from './pages/firmware-page'
import UpdatePage from './pages/update-page'
import AdminHistoryPage from './pages/admin-history-page'
import AdminPage from './pages/admin-page'
import ModelPage from './pages/model-page'

function App() {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/" element={<LoginPage />} />
      </Route>
      <Route element={<ProtectRoute />}>
        <Route path="/user" element={<UserPage />} />
        <Route path="/user/active" element={<UserActivePage />} />
        <Route path="/user/chart" element={<UserChartPage />} />
        <Route path="/user/history" element={<UserHistoryPage />} />
        <Route path="/push/mail" element={<MailPage />} />
        <Route path="/push" element={<PushPage />} />
        <Route path="/push/log" element={<PushHistoryPage />} />
        <Route path="/policy" element={<PolicyPage />} />
        <Route path="/policy/guide" element={<UserGuidePage />} />
        <Route path="/policy/version" element={<PolicyAppVersionPage />} />
        <Route path="/firmware" element={<FirmwarePage />} />
        <Route path="/firmware/update" element={<UpdatePage />} />
        <Route path="/admin/history" element={<AdminHistoryPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/device" element={<ModelPage />} />
      </Route>
    </Routes>
  )
}

export default App
