// ** Mui Imports
import {
  Card,
  Button,
  Typography,
  Grid,
  TextField,
  Tab,
  Tabs,
} from '@mui/material'

// ** Type Imports
import { Policy } from '@/types'

// ** Component Imports
import SelectModal from '../../components/modal/select-modal'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface Props {
  value: number
  policyData: Policy
  open: boolean
  handleClose: () => void
  handleOpen: () => void
  setPolicy: (e: any) => void
  handleChange: (event: React.SyntheticEvent, newValue: number) => void
  modContent: () => void
}

const PolicyPageView = ({
  value,
  handleChange,
  policyData,
  setPolicy,
  modContent,
  handleClose,
  handleOpen,
  open,
}: Props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="개인정보 처리 방침" {...a11yProps(0)} />
            <Tab label="마케팅 및 광고 동의 설정" {...a11yProps(1)} />
          </Tabs>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3">{policyData.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={30}
                label={policyData.title}
                placeholder="내용을 입력하세요"
                value={policyData.text}
                name="text"
                onChange={setPolicy}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Button variant="contained" onClick={handleOpen}>
                저장
              </Button>
            </Grid>
          </Grid>
          {open && (
            <SelectModal
              state={open}
              closeEvent={handleClose}
              event={modContent}
              message="정책을 변경하시겠습니까?"
              one="수정"
              two="닫기"
            />
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

export default PolicyPageView
