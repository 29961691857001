import { api } from '../api'

export const historyApi = api
  .enhanceEndpoints({
    addTagTypes: ['History'],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      findLoginHistory: builder.query({
        query: (args) => ({
          url: '/user/loginHistory',
          params: args,
        }),
      }),
      findManageList: builder.query({
        query: (args) => ({
          url: '/admin/manage',
          params: args,
        }),
      }),
      saveUserManual: builder.mutation({
        query: (args) => ({
          url: '/admin/manage',
          method: 'POST',
          body: args,
        }),
      }),
    }),
  })

export const {
  useLazyFindLoginHistoryQuery,
  useLazyFindManageListQuery,
  useSaveUserManualMutation,
} = historyApi
