// ** Mui Imoport
import {
  Dialog,
  DialogTitle,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
} from '@mui/material'

// ** Component Imports
import SimpleUploader from '../../../components/simpleUploader'
import SelectModal from '../../../components/modal/select-modal'

interface Props {
  state: boolean
  isNew: boolean
  open: boolean
  updater: { os: string; filePath: string; fileName: string }
  editOpen: boolean
  handleOpen: () => void
  handleClose: () => void
  setUpdater: (e: any) => void
  setFilePath: (filePath: string) => void
  regContent: () => void
  modContent: () => void
  closeEvent: () => void
  handleEditOpen: () => void
  handleEditClose: () => void
  setFileName: (fileName: string) => void
}

const UpdateModalView = ({
  state,
  closeEvent,
  isNew,
  updater,
  setUpdater,
  regContent,
  modContent,
  setFilePath,
  open,
  handleClose,
  handleOpen,
  editOpen,
  handleEditClose,
  handleEditOpen,
  setFileName,
}: Props) => {
  return (
    <Dialog
      maxWidth="sm"
      open={state}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEvent()
        }
      }}
      fullWidth
    >
      <DialogTitle>업데이터 {isNew ? '등록' : '수정'}</DialogTitle>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="model">OS</InputLabel>
            <Select
              labelId="model"
              label="모델"
              value={updater.os}
              name="os"
              onChange={setUpdater}
              disabled={isNew ? false : true}
            >
              <MenuItem value="MAC">MAC</MenuItem>
              <MenuItem value="WINDOW">WINDOW</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <SimpleUploader
            type="file"
            setPath={setFilePath}
            file={updater.fileName}
            setName={setFileName}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2}>
          <Button variant="contained" color="error" onClick={closeEvent}>
            취소
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={isNew ? handleOpen : handleEditOpen}
          >
            {isNew ? '저장' : '수정'}
          </Button>
        </Grid>
        <Grid item xs={4} />
      </Grid>
      {open && (
        <SelectModal
          state={open}
          closeEvent={handleClose}
          event={regContent}
          message="정말 등록하시겠습니까?"
          one="등록"
          two="닫기"
        />
      )}
      {editOpen && (
        <SelectModal
          state={editOpen}
          closeEvent={handleEditClose}
          event={modContent}
          message="정말 수정하시겠습니까?"
          one="수정"
          two="닫기"
        />
      )}
    </Dialog>
  )
}

export default UpdateModalView
