// ** Mui Imports
import {
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
} from '@mui/material'

// ** Component Imports
import SimpleUploader from '../../../components/simpleUploader'
import SelectModal from '../../../components/modal/select-modal'

// ** Type Imports
import { Model } from '@/types'

interface Props {
  state: boolean
  isNew: boolean
  open: boolean
  editOpen: boolean
  modelData: Model[]
  firmware: {
    modelId: string
    filePath: string
    version: string
    name: string
    fileName: string
  }
  closeEvent(): void
  setFirmware: (e: any) => void
  regContent: () => void
  modContent: () => void
  setData: (e: any) => void
  setFilePath: (filePath: string) => void
  handleOpen: () => void
  handleClose: () => void
  handleEditOpen: () => void
  handleEditClose: () => void
  setFileName: (fileName: string) => void
}

const AppVersionModalView = ({
  state,
  closeEvent,
  isNew,
  modelData,
  firmware,
  setFirmware,
  regContent,
  modContent,
  setData,
  setFilePath,
  open,
  handleClose,
  handleOpen,
  handleEditClose,
  handleEditOpen,
  editOpen,
  setFileName,
}: Props) => {
  return (
    <Dialog
      maxWidth="sm"
      open={state}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEvent()
        }
      }}
      fullWidth
    >
      <DialogTitle>펌웨어 {isNew ? '등록' : '수정'}</DialogTitle>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="model">모델</InputLabel>
            <Select
              labelId="model"
              label="모델"
              defaultValue=""
              value={firmware.modelId}
              name="modelId"
              onChange={setFirmware}
            >
              <MenuItem value="">전체</MenuItem>
              {modelData.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <SimpleUploader
            type="file"
            setPath={setFilePath}
            file={firmware.fileName}
            setName={setFileName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="버전"
            placeholder="펌웨어 버전을 입력해주세요."
            name="version"
            value={firmware.version}
            onChange={(e) => {
              const versionRegex = /^[\d.*]+$/
              const { value } = e.target
              if (!versionRegex.test(value)) {
                const sanitizedValue = value.replace(/[^*\d.]/g, '')
                setData({ ...firmware, version: sanitizedValue })

                return
              }
              setFirmware(e)
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'right', m: -2 }}>
          <Typography variant="body2" sx={{ color: 'grey' }}>
            * 숫자, 온점만 입력 가능
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="펌웨어 네임"
            placeholder="펌웨어 네임을 입력해주세요."
            value={firmware.name}
            name="name"
            onChange={setFirmware}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2}>
          <Button variant="contained" color="error" onClick={closeEvent}>
            취소
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={isNew ? handleOpen : handleEditOpen}
          >
            {isNew ? '저장' : '수정'}
          </Button>
        </Grid>
        <Grid item xs={4} />
        {open && (
          <SelectModal
            state={open}
            closeEvent={handleClose}
            event={regContent}
            message="정말 등록하시겠습니까?"
            one="등록"
            two="닫기"
          />
        )}
        {editOpen && (
          <SelectModal
            state={editOpen}
            closeEvent={handleEditClose}
            event={modContent}
            message="정말 수정하시겠습니까?"
            one="수정"
            two="닫기"
          />
        )}
      </Grid>
    </Dialog>
  )
}

export default AppVersionModalView
