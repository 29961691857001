import { api } from '../api'

export const guideApi = api
  .enhanceEndpoints({
    addTagTypes: ['Guide'],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      findUserManualList: builder.query({
        query: (args) => ({
          url: '/manual',
          params: args,
        }),
      }),
      findManual: builder.query({
        query: (id) => ({
          url: `/manual/${id}`,
        }),
      }),
      saveManual: builder.mutation({
        query: (args) => ({
          url: '/manual',
          method: 'POST',
          body: args,
        }),
      }),
      updateManual: builder.mutation({
        query: (args) => ({
          url: '/manual',
          method: 'PUT',
          body: args,
        }),
      }),
      deleteManual: builder.mutation({
        query: (args) => ({
          url: '/manual/delete',
          method: 'POST',
          body: args,
        }),
      }),
      findVideoList: builder.query({
        query: (args) => ({
          url: '/video',
          params: args,
        }),
      }),
      findVideo: builder.query({
        query: (id) => ({
          url: `/video/${id}`,
        }),
      }),
      saveVideo: builder.mutation({
        query: (args) => ({
          url: '/video',
          method: 'POST',
          body: args,
        }),
      }),
      updateVideo: builder.mutation({
        query: (args) => ({
          url: '/video',
          method: 'PUT',
          body: args,
        }),
      }),
      deleteVideo: builder.mutation({
        query: (args) => ({
          url: '/video/delete',
          method: 'POST',
          body: args,
        }),
      }),
      findFaqList: builder.query({
        query: (args) => ({
          url: '/model/faq/list',
          params: args,
        }),
      }),
      saveFaq: builder.mutation({
        query: (args) => ({
          url: '/model/faq',
          method: 'POST',
          body: args,
        }),
      }),
      findFaq: builder.query({
        query: (id) => ({
          url: `/model/faq/${id}`,
        }),
      }),
      updateFaq: builder.mutation({
        query: (args) => ({
          url: '/model/faq',
          method: 'PUT',
          body: args,
        }),
      }),
      deleteFaq: builder.mutation({
        query: (args) => ({
          url: '/model/faq/delete',
          method: 'POST',
          body: args,
        }),
      }),
    }),
  })

export const {
  useFindUserManualListQuery,
  useFindVideoListQuery,
  useFindFaqListQuery,
  useSaveVideoMutation,
  useLazyFindVideoQuery,
  useUpdateVideoMutation,
  useDeleteVideoMutation,
  useSaveFaqMutation,
  useLazyFindFaqQuery,
  useUpdateFaqMutation,
  useDeleteFaqMutation,
  useSaveManualMutation,
  useLazyFindManualQuery,
  useUpdateManualMutation,
  useDeleteManualMutation,
} = guideApi
