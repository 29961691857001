// ** Mui Imports
import { Dates, PushHistory, PushLog } from '@/types'
import {
  Box,
  Card,
  Button,
  Typography,
  Stack,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'

import dayjs, { Dayjs } from 'dayjs'
import DetailModal from './detail-modal'
import DateRangePicker from '../../components/datepicker'

const defaultColumns: GridColumns = [
  {
    flex: 0.15,
    field: 'id',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '번호',
    renderCell: ({ row }: { row: PushLog }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.id}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'type',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '형태',
    renderCell: ({ row }: { row: PushLog }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.pushType}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'title',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '제목',
    renderCell: ({ row }: { row: PushLog }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.title}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'date',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '전송 일시',
    renderCell: ({ row }: { row: PushLog }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {dayjs(row.regDate).format('YYYY.MM.DD HH:mm:ss')}
        </Typography>
      </Box>
    ),
  },
]

interface Props {
  data: PushLog[]
  open: boolean
  count: number
  query: { pushType: string; title: string }
  dates: Dates
  page: number
  pushLogId: number
  setPage: (e: any) => void
  handleRefetch: () => void
  handleQueryInit: () => void
  setQuery: (e: any) => void
  setDates: (e: any) => void
  handleClose: () => void
  handleOpen: (id: number) => void
}

const PushHistoryPageView = ({
  data,
  handleOpen,
  open,
  handleClose,
  page,
  dates,
  setPage,
  setDates,
  query,
  setQuery,
  count,
  handleQueryInit,
  handleRefetch,
  pushLogId,
}: Props) => {
  const columns: any = [
    ...defaultColumns,
    {
      flex: 0.15,
      field: 'btn',
      minWidth: 30,
      headerAlign: 'center',
      headerName: '보기',
      renderCell: ({ row }: { row: PushHistory }) => (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button onClick={() => handleOpen(row.id)}>보기</Button>
        </Box>
      ),
    },
  ]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DateRangePicker dates={dates} setDates={setDates} />
            </Grid>
            <Grid item xs={1}>
              <FormControl fullWidth>
                <InputLabel id="type">형태</InputLabel>
                <Select
                  labelId="type"
                  label="형태"
                  defaultValue=""
                  value={query.pushType}
                  name="pushType"
                  onChange={setQuery}
                >
                  <MenuItem value="">전체</MenuItem>
                  <MenuItem value="EMAIL">메일</MenuItem>
                  <MenuItem value="PUSH">푸시</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="제목"
                placeholder="제목 검색"
                name="title"
                value={query.title}
                onChange={setQuery}
              />
            </Grid>
            <Grid item xs={1} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={handleRefetch}
              >
                조회
              </Button>
            </Grid>
            <Grid item xs={1} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                color="error"
                size="large"
                fullWidth
                onClick={handleQueryInit}
              >
                초기화
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <DataGrid
            columns={columns}
            rows={data}
            rowCount={count}
            autoHeight
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            disableColumnMenu
            pagination
            page={page}
            paginationMode="server"
            pageSize={10}
            onPageChange={(newPage) => {
              setPage(newPage)
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  등록된 정보가 없습니다.
                </Stack>
              ),
            }}
          />
        </Card>
      </Grid>
      {open && (
        <DetailModal
          state={open}
          closeEvent={handleClose}
          pushLogId={pushLogId}
        />
      )}
    </Grid>
  )
}

export default PushHistoryPageView
