import { api } from '../api'

export const pushApi = api
  .enhanceEndpoints({
    addTagTypes: ['Push'],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      pushMail: builder.mutation({
        query: (args) => ({
          url: '/push/email',
          method: 'POST',
          body: args,
        }),
      }),
      pushApp: builder.mutation({
        query: (args) => ({
          url: '/push/app',
          method: 'POST',
          body: args,
        }),
      }),
      findPushList: builder.query({
        query: (args) => ({
          url: '/push',
          params: args,
        }),
      }),
      findPush: builder.query({
        query: (id) => ({
          url: `/push/${id}`,
        }),
      }),
    }),
  })

export const {
  usePushMailMutation,
  useLazyFindPushListQuery,
  useLazyFindPushQuery,
  usePushAppMutation,
} = pushApi
