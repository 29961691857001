// ** Mui Imports
import {
  Box,
  Card,
  Button,
  Typography,
  Grid,
  Stack,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'

// ** Other Imports
import { Firmware, Model } from '@/types'
import dayjs from 'dayjs'

// ** Component Imports
import AppVersionModal from './firmware-page-modal'
import SelectModal from '../../components/modal/select-modal'

// ** Type Imports
import type { GetFirmwareParmas } from '@/types/firmware'

const defaultColumns: GridColumns = [
  {
    flex: 0.15,
    field: 'id',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '번호',
    renderCell: ({ row }: { row: Firmware }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.id}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'model',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '모델명',
    renderCell: ({ row }: { row: Firmware }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.model.name}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'name',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '펌웨어 네임',
    renderCell: ({ row }: { row: Firmware }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.name}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'version',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '펌웨어 정보',
    renderCell: ({ row }: { row: Firmware }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">{row.version}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'date',
    minWidth: 30,
    headerAlign: 'center',
    headerName: '등록일',
    renderCell: ({ row }: { row: Firmware }) => (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body2">
          {dayjs(row.regDate).format('YYYY.MM.DD HH:mm:ss')}
        </Typography>
      </Box>
    ),
  },
]

interface Props {
  data: Firmware[]
  isNew: boolean
  count: number
  page: number
  open: boolean
  delOpen: boolean
  firmwareId: number
  selectedRows: number[]
  modelData: Model[]
  query: GetFirmwareParmas
  setPage: (e: any) => void
  handleOpen: (state: boolean, id?: number) => void
  handleClose: () => void
  setSelectedRows: (e: any) => void
  handleRefetch: () => void
  setQuery: (e: any) => void
  delContent: () => void
  handleDelOpen: () => void
  handleDelClose: () => void
  handleInit: () => void
}

const FirmwarePageView = ({
  data,
  open,
  handleClose,
  handleOpen,
  isNew,
  page,
  setPage,
  count,
  selectedRows,
  setSelectedRows,
  handleRefetch,
  setQuery,
  modelData,
  query,
  firmwareId,
  handleDelClose,
  handleDelOpen,
  delContent,
  delOpen,
  handleInit,
}: Props) => {
  const columns: any = [
    ...defaultColumns,
    {
      flex: 0.15,
      field: 'btn',
      minWidth: 30,
      headerAlign: 'center',
      headerName: '수정',
      renderCell: ({ row }: { row: Firmware }) => (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button onClick={() => handleOpen(false, row.id)}>수정</Button>
        </Box>
      ),
    },
  ]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <Typography variant="h4">펌웨어 조회</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="model">모델</InputLabel>
                <Select
                  labelId="model"
                  label="모델"
                  defaultValue=""
                  value={String(query.modelId)}
                  name="modelId"
                  onChange={setQuery}
                >
                  <MenuItem value="">전체</MenuItem>
                  {modelData.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={handleRefetch}
              >
                조회
              </Button>
            </Grid>
            <Grid item xs={1} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                color="error"
                onClick={handleInit}
              >
                초기화
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 5 }}>
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={4}>
              <Typography variant="h4">펌웨어 리스트</Typography>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleOpen(true)}
              >
                등록
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={handleDelOpen}
              >
                선택 삭제
              </Button>
            </Grid>
          </Grid>
          <DataGrid
            columns={columns}
            rows={data}
            rowCount={count}
            autoHeight
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            disableColumnMenu
            checkboxSelection
            onSelectionModelChange={(row) => setSelectedRows(row)}
            selectionModel={selectedRows}
            pagination
            page={page}
            paginationMode="server"
            pageSize={10}
            onPageChange={(newPage) => {
              setPage(newPage)
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  등록된 정보가 없습니다.
                </Stack>
              ),
            }}
          />
        </Card>
      </Grid>
      {open && (
        <AppVersionModal
          state={open}
          closeEvent={handleClose}
          isNew={isNew}
          handleRefetch={handleRefetch}
          firmwareId={firmwareId}
        />
      )}
      {delOpen && (
        <SelectModal
          state={delOpen}
          closeEvent={handleDelClose}
          event={delContent}
          message="정말로 삭제하시겠습니까?"
          one="삭제"
          two="취소"
        />
      )}
    </Grid>
  )
}

export default FirmwarePageView
