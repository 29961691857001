export const getDayOneMonth = (year: number, month: number) => {
  if (year % 4 === 0 && month === 2) {
    return 29
  }
  switch (month) {
    case 1:
      return 31
    case 2:
      return 28
    case 3:
      return 31
    case 4:
      return 30
    case 5:
      return 31
    case 6:
      return 30
    case 7:
      return 31
    case 8:
      return 31
    case 9:
      return 30
    case 10:
      return 31
    case 11:
      return 30
    case 12:
      return 31
    default:
      return 31
  }
}

export const getDayOne3Month = (year: number, month: number) => {
  if (year % 4 === 0) {
    if (month === 12) {
      return 31 + 31 + 29
    }

    if (month === 1) {
      return 31 + 29 + 31
    }

    if (month === 2) {
      return 29 + 31 + 30
    }
  }

  switch (month) {
    case 1:
      return 31 + 28 + 31
    case 2:
      return 28 + 31 + 30
    case 3:
      return 31 + 30 + 31
    case 4:
      return 30 + 31 + 30
    case 5:
      return 31 + 30 + 31
    case 6:
      return 30 + 31 + 31
    case 7:
      return 31 + 31 + 30
    case 8:
      return 31 + 30 + 31
    case 9:
      return 30 + 31 + 30
    case 10:
      return 31 + 30 + 31
    case 11:
      return 30 + 31 + 31
    case 12:
      return 31 + 31 + 28
    default:
      return 31
  }
}

export const getYearList = () => {
  const currentYear = new Date().getFullYear()
  const years = []

  for (let year = 2023; year <= currentYear; year++) {
    years.push(year)
  }

  return years
}
