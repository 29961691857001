// ** React Imports
import { useEffect, useState } from 'react'

// ** Component Imports
import PushHistoryPageView from './push-history-page'

// ** Redux Imports
import { useLazyFindPushListQuery } from '../../services'

// ** Type Imports
import { PushLog } from '@/types'

// ** Util Imports
import useInput from '../../hooks/useInput'
import dayjs, { Dayjs } from 'dayjs'

const PushHistoryPage = () => {
  const [open, setOpen] = useState<boolean>(false)

  const {
    data: query,
    handler: setQuery,
    setData,
  } = useInput({ pushType: '', title: '' })

  const [date, setDate] = useState<{ startDate: Dayjs; endDate: Dayjs }>({
    startDate: dayjs().subtract(1, 'month'),
    endDate: dayjs(),
  })
  const [page, setPage] = useState<number>(0)
  const [logData, setLogData] = useState<PushLog[]>([])
  const [count, setCount] = useState<number>(0)

  const [pushLogId, setPushLogId] = useState<number>(0)

  const [reRenderSwitch, setReRenderSwitch] = useState<boolean>(false)

  const [findLogApi] = useLazyFindPushListQuery()

  const args = () => {
    const arg: {
      page: number
      pageSize: number
      startDate: string
      endDate: string
      title?: string
      pushType?: string
    } = {
      page,
      pageSize: 10,
      startDate: date.startDate.format('YYYY-MM-DD'),
      endDate: date.endDate.add(1, 'day').format('YYYY-MM-DD'),
    }
    if (query.title !== '') arg.title = query.title
    if (query.pushType !== '') arg.pushType = query.pushType
    return arg
  }

  const handleOpen = (id: number) => {
    setPushLogId(id)
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const handleRefetch = () => setReRenderSwitch(true)
  const handleQueryInit = () => {
    setData({ pushType: '', title: '' })
    setDate({ startDate: dayjs().subtract(1, 'month'), endDate: dayjs() })
    setPage(0)
    handleRefetch()
  }
  useEffect(() => {
    if (reRenderSwitch) {
      setReRenderSwitch(false)
    }

    findLogApi(args())
      .unwrap()
      .then((res) => {
        setLogData(res.data.data)
        setCount(res.data.count)
      })
  }, [reRenderSwitch, page])

  return (
    <PushHistoryPageView
      data={logData}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      pushLogId={pushLogId}
      query={query}
      setQuery={setQuery}
      dates={date}
      setDates={setDate}
      count={count}
      page={page}
      setPage={setPage}
      handleQueryInit={handleQueryInit}
      handleRefetch={handleRefetch}
    />
  )
}

export default PushHistoryPage
