// ** React Imports
import { useEffect, useState } from 'react'

// ** Hooks Imports
import useInput from '../../hooks/useInput'

// ** Component Imports
import UserHistoryView from './user-history-page'

// ** Type Imports
import { LoginHistory, InfoAccessLog, Dates } from '@/types'
import { ManageHistoryParmas, type LoginHistoryParams } from '@/types/history'

// ** Utils Imports
import dayjs from 'dayjs'

// ** Redux Imports
import {
  useLazyFindLoginHistoryQuery,
  useLazyFindManageListQuery,
} from '../../services'

const UserHistoryPage = () => {
  const [date, setDate] = useState<Dates>({
    startDate: dayjs().subtract(1, 'month'),
    endDate: dayjs(),
  })

  const [value, setValue] = useState<number>(0)

  const [page, setPage] = useState<number>(0)
  const [reRenderSwitch, setReRenderSwitch] = useState<boolean>(false)

  const [loginData, setLoginData] = useState<LoginHistory[]>([])
  const [manageData, setManageData] = useState<InfoAccessLog[]>([])
  const [count, setCount] = useState<number>(0)

  const {
    data: query,
    handler: setQuery,
    setData,
  } = useInput<LoginHistoryParams>({
    name: '',
  })
  const {
    data: manageQuery,
    handler: setManageQuery,
    selectHandler: setSelectManageQuery,
    setData: setDataManage,
  } = useInput<ManageHistoryParmas>({
    pageName: '',
    type: '',
    name: '',
    admin: '',
  })

  const [findLoginHistoryApi] = useLazyFindLoginHistoryQuery()
  const [findManageListApi] = useLazyFindManageListQuery()

  const handleRefetch = () => setReRenderSwitch(true)

  const handleQueryInit = () => {
    if (value === 0) {
      setDate({
        startDate: dayjs().subtract(1, 'month'),
        endDate: dayjs(),
      })
      setData({ name: '' })
      setDataManage({ pageName: '', type: '', name: '', admin: '' })
      setPage(0)
    }

    if (value === 1) {
      setDate({
        startDate: dayjs().subtract(1, 'month'),
        endDate: dayjs(),
      })
      setData({ name: '' })
      setDataManage({ pageName: '', type: '', name: '', admin: '' })
      setPage(0)
    }

    handleRefetch()
  }

  const args1 = () => {
    const arg: {
      page: number
      pageSize: number
      startDate: string
      endDate: string
      email?: string
    } = {
      page,
      pageSize: 10,
      startDate: dayjs(date.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(date.endDate).add(1, 'day').format('YYYY-MM-DD'),
    }

    if (query.name !== '') arg.email = query.name

    return arg
  }

  const args2 = () => {
    const arg: {
      page: number
      pageSize: number
      startDate: string
      endDate: string
      pageName?: string
      admin?: string
      type?: string
    } = {
      page,
      pageSize: 10,
      startDate: dayjs(date.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(date.endDate).add(1, 'day').format('YYYY-MM-DD'),
    }

    if (manageQuery.type !== '') arg.type = manageQuery.type
    if (manageQuery.pageName !== '') arg.pageName = manageQuery.pageName
    if (manageQuery.admin !== '') arg.admin = manageQuery.admin

    return arg
  }

  useEffect(() => {
    if (reRenderSwitch) {
      setReRenderSwitch(false)
    }

    if (value === 0) {
      findLoginHistoryApi(args1())
        .unwrap()
        .then((res) => {
          setLoginData(res.data.data)
          setCount(res.data.count)
        })
    }

    if (value === 1) {
      findManageListApi(args2())
        .unwrap()
        .then((res) => {
          setManageData(res.data.data)
          setCount(res.data.count)
        })
    }
  }, [value, page, reRenderSwitch])

  useEffect(() => {
    setDate({ startDate: dayjs().subtract(1, 'month'), endDate: dayjs() })
    setCount(0)
    if (value === 0) {
      setData({ name: '' })
      setDataManage({ pageName: '', type: '', name: '', admin: '' })
      setManageData([])
      setLoginData([])
    }

    if (value === 1) {
      setData({ name: '' })
      setDataManage({ pageName: '', type: '', name: '', admin: '' })
      setManageData([])
      setLoginData([])
    }
  }, [value])

  // useEffect(() => {
  //   if (value === 1) {
  //     setData({ ...query, type: '' })
  //   }
  // }, [query.pageName])

  return (
    <UserHistoryView
      setManageQuery={setManageQuery}
      loginData={loginData}
      manageData={manageData}
      query={query}
      setQuery={setQuery}
      dates={date}
      setDates={setDate}
      handleRefetch={handleRefetch}
      count={count}
      page={page}
      setPage={setPage}
      value={value}
      handleQueryInit={handleQueryInit}
      setValue={setValue}
      setSelectManageQuery={setSelectManageQuery}
      manageQuery={manageQuery}
    />
  )
}

export default UserHistoryPage
