// ** React Imports
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

// ** Mui Imports
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'

// ** Config Imports
import {
  REACT_APP_S3_BUCKET_ACCESSKEY,
  REACT_APP_S3_BUCKET_NAME,
  REACT_APP_S3_BUCKET_REGION,
  REACT_APP_S3_BUCKET_SECRETKEY,
} from '../../config'
import { S3 } from 'aws-sdk'

interface Props {
  setPath: (e: string) => void
  image: string
}

const ImagePreview = ({ setPath, image }: Props) => {
  const inputRef = useRef<HTMLInputElement | any>(null)

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  const handleFileUpload = (file: any) => {
    const s3 = new S3({
      region: REACT_APP_S3_BUCKET_REGION || '',
      credentials: {
        accessKeyId: REACT_APP_S3_BUCKET_ACCESSKEY || '',
        secretAccessKey: REACT_APP_S3_BUCKET_SECRETKEY || '',
      },
    })

    const fileObject = {
      Bucket: REACT_APP_S3_BUCKET_NAME || '',
      Key: file.name,
      Body: file,
      ContentType: file.type,
    }

    s3.upload(fileObject, (err: any, data: any) => {
      if (err) {
        console.error('Error uploading file:', err)
      } else {
        setPath && setPath(data.Location)
      }
    })
  }

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      handleFileUpload(file)
    }
  }

  useEffect(() => {
    if (image === '') {
      clearInput()
    }
  }, [image])
  return (
    <Grid container spacing={3} sx={{ alignItems: 'center' }}>
      <Grid item xs={3}>
        {image ? (
          <img
            src={image}
            alt="Preview"
            style={{ width: '100%', height: 200 }}
          />
        ) : (
          <Box sx={{ border: 3, width: '100%', height: 200 }} />
        )}
      </Grid>
      <Grid item xs={8} sx={{ textAlign: 'left' }}>
        <Typography variant="h6">전송할 이미지를 첨부해주세요</Typography>
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={handleImageChange}
          style={{ marginTop: 10, marginBottom: 10 }}
        />
        <Typography variant="body2">
          (권장 이미지 : 500x500 / 100mb git, jpg, png, jpeg)
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ImagePreview
