import { api } from '../api'

export const adminHistoryApi = api
  .enhanceEndpoints({
    addTagTypes: ['AdminHistory'],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      findAdminHistoryList: builder.query({
        query: (args) => ({
          url: '/admin/log',
          params: args,
        }),
      }),
      findAdminHistory: builder.query({
        query: (id) => ({
          url: `/admin/log/${id}`,
        }),
      }),
      saveAdminHistory: builder.mutation({
        query: (args) => ({
          url: '/admin/log',
          method: 'POST',
          body: args,
        }),
      }),
      updateAdminHistory: builder.mutation({
        query: (args) => ({
          url: '/admin/log',
          method: 'PUT',
          body: args,
        }),
      }),
      deleteAdminHistory: builder.mutation({
        query: (args) => ({
          url: '/admin/log/delete',
          method: 'POST',
          body: args,
        }),
      }),
    }),
  })

export const {
  useLazyFindAdminHistoryListQuery,
  useLazyFindAdminHistoryQuery,
  useUpdateAdminHistoryMutation,
  useSaveAdminHistoryMutation,
  useDeleteAdminHistoryMutation,
} = adminHistoryApi
