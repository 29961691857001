// ** React Imports
import { useEffect, useMemo, useState } from 'react'

// ** Component Imports
import UserPageView from './user-page'

// ** Redux Imports
import {
  useFindModelListNoPaginationQuery,
  useLazyFindUserListQuery,
  useSaveUserManualMutation,
} from '../../services'

// ** Type Imports
import { User } from '@/types'
import type { UserParmas } from '@/types/user'

// ** Utils Imports
import { isUndefined } from 'lodash'
import useInput from '../../hooks/useInput'
import excelDownload from '../../utils/excel'
import dayjs from 'dayjs'

const UserPage = () => {
  const {
    data: query,
    handler: setQuery,
    setData,
  } = useInput<UserParmas>({
    email: '',
    type: '',
    socialType: '',
    lang: '',
    osType: '',
    modelId: 0,
  })

  const [page, setPage] = useState<number>(0)
  const [reRenderSwitch, setReRenderSwitch] = useState<boolean>(false)

  const [userList, setUserList] = useState<User[]>([])
  const [count, setCount] = useState<number>(0)

  const args = () => {
    const arg: {
      page: number
      pageSize: number
      email?: string
      socialType?: string
      lang?: string
      type?: string
      osType?: string
      modelId?: number
    } = {
      page,
      pageSize: 10,
    }
    if (query.type !== '') arg.type = query.type
    if (query.email !== '') arg.email = query.email
    if (query.socialType !== '') arg.socialType = query.socialType
    if (query.lang !== '') arg.lang = query.lang
    if (query.osType !== '') arg.osType = query.osType
    if (query.modelId !== 0) arg.modelId = query.modelId

    return arg
  }

  const { data: modelList } = useFindModelListNoPaginationQuery('')

  const modelData = useMemo(
    () => (isUndefined(modelList) ? [] : modelList.data.data),
    [modelList],
  )

  const [findUserApi] = useLazyFindUserListQuery()
  const [saveManage] = useSaveUserManualMutation()

  const handleRefetch = () => setReRenderSwitch(true)
  const handleInitQuery = () => {
    setData({
      email: '',
      type: '',
      socialType: '',
      lang: '',
      osType: '',
      modelId: 0,
    })
    handleRefetch()
  }

  const handleExcelDownload = () => {
    findUserApi({ ...args(), page: 0, pageSize: count })
      .unwrap()
      .then((res) => {
        excelDownload({
          data: res.data.data.map((item: User) => ({
            번호: item.id,
            아이디: item.email,
            상태: item.status,
            '가입 형태': item.socialType,
            플랫폼: item.os,
            모델: item.model ? item.model : '-',
            가입일: item.regDate,
            '최근 접속': item.lastAccessTime ? item.lastAccessTime : '-',
          })),
          filename: dayjs().format('YYYY-MM-DD') + '유저 리스트',
          colsSize: [150, 300, 200, 200, 200, 200, 200, 300, 300],
        })

        saveManage({ pageName: '사용자 조회', type: 'EXCEL' })
          .unwrap()
          .then((res) => {})
      })
  }

  useEffect(() => {
    if (reRenderSwitch) {
      setReRenderSwitch(false)
    }

    findUserApi(args())
      .unwrap()
      .then((res) => {
        setUserList(res.data.data)
        setCount(res.data.count)
      })
  }, [reRenderSwitch, page])

  useEffect(() => {
    saveManage({ pageName: '사용자 조회', type: 'READ' })
      .unwrap()
      .then((res) => {})
  }, [])

  return (
    <UserPageView
      data={userList}
      handleRefetch={handleRefetch}
      page={page}
      setPage={setPage}
      count={count}
      query={query}
      setQuery={setQuery}
      modelData={modelData}
      handleInitQuery={handleInitQuery}
      handleExcelDownload={handleExcelDownload}
    />
  )
}

export default UserPage
