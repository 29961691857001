// ** Component Imports
import { useSaveAppVersionMutation } from '../../../services'
import useInput from '../../../hooks/useInput'
import AppVersionModalView from './app-version-modal'

interface Props {
  state: boolean
  closeEvent(): void
  handleRefetch: () => void
}

const AppVersionModal = ({ state, closeEvent, handleRefetch }: Props) => {
  const {
    data: appVersion,
    handler: setAppVersion,
    setData,
  } = useInput({
    type: '',
    text: '',
    version: '',
  })

  const [saveAppVersion] = useSaveAppVersionMutation()

  const regContent = () => {
    if (appVersion.type === '') {
      alert('타입을 선택하세요.')

      return
    }

    if (appVersion.text === '') {
      alert('내용을 입력하세요.')

      return
    }

    if (appVersion.version === '') {
      alert('버전을 입력하세요.')

      return
    }

    saveAppVersion(appVersion)
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('앱 버전이 생성되었습니다.')
          setData({ type: '', text: '', version: '' })
          handleRefetch()
          closeEvent()
        }
      })
      .catch((err) => alert(err.data.message))
  }

  return (
    <AppVersionModalView
      state={state}
      closeEvent={closeEvent}
      appVersion={appVersion}
      setAppVersion={setAppVersion}
      regContent={regContent}
    />
  )
}

export default AppVersionModal
