// ** Component Imports
import UserGuidePageView from './user-guide-page'
import { useEffect, useMemo, useState } from 'react'
import {
  useDeleteFaqMutation,
  useDeleteManualMutation,
  useDeleteVideoMutation,
  useFindFaqListQuery,
  useFindModelListNoPaginationQuery,
  useFindUserManualListQuery,
  useFindVideoListQuery,
} from '../../services'
import { isUndefined } from 'lodash'
import useInput from '../../hooks/useInput'

const UserGuidePage = () => {
  const [page, setPage] = useState<number>(0)

  const [menualOpen, setMenualOpen] = useState<boolean>(false)
  const [videoOpen, setVideoOpen] = useState<boolean>(false)
  const [faqOpen, setFaqOpen] = useState<boolean>(false)

  const [delOpen, setDelOpen] = useState<boolean>(false)

  const [isNew, setIsNew] = useState<boolean>(true)

  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const [videoId, setVideoId] = useState<number>(0)
  const [faqId, setFaqId] = useState<number>(0)
  const [manualId, setManualId] = useState<number>(0)

  const { data: query, handler: setQuery } = useInput({ modelId: '' })

  const [deleteVideo] = useDeleteVideoMutation()
  const [deleteFaq] = useDeleteFaqMutation()
  const [deleteManual] = useDeleteManualMutation()

  const { data: manualList, refetch: manualRefetch } =
    useFindUserManualListQuery({
      page,
      pageSize: 10,
    })

  const { data: videoList, refetch: videoRefetch } = useFindVideoListQuery({
    page,
    pageSize: 10,
  })

  const { data: faqList, refetch: faqRefetch } = useFindFaqListQuery(
    query.modelId === ''
      ? {
          page,
          pageSize: 10,
        }
      : { page, pageSize: 10, modelId: query.modelId },
  )

  const { data: modelList } = useFindModelListNoPaginationQuery('')

  const manualData = useMemo(
    () => (isUndefined(manualList) ? { data: [], count: 0 } : manualList.data),
    [manualList],
  )

  const videoData = useMemo(
    () => (isUndefined(videoList) ? { data: [], count: 0 } : videoList.data),
    [videoList],
  )

  const faqData = useMemo(
    () => (isUndefined(faqList) ? { data: [], count: 0 } : faqList.data),
    [faqList],
  )

  const modelData = useMemo(
    () => (isUndefined(modelList) ? [] : modelList.data.data),
    [modelList],
  )

  const handleManualOpen = (state: boolean, manualId?: number) => {
    setMenualOpen(true)
    setIsNew(state)
    if (manualId) {
      setManualId(manualId)
    }
  }
  const handleManualClose = () => setMenualOpen(false)

  const handleVideoOpen = (state: boolean, videoId?: number) => {
    setVideoOpen(true)
    setIsNew(state)
    if (videoId) {
      setVideoId(videoId)
    }
  }
  const handleVideoClose = () => setVideoOpen(false)

  const handleFaqOpen = (state: boolean, faqId?: number) => {
    setFaqOpen(true)
    setIsNew(state)
    if (faqId) {
      setFaqId(faqId)
    }
  }
  const handleFaqClose = () => setFaqOpen(false)

  const handleDelOpen = () => setDelOpen(true)
  const handleDelClose = () => setDelOpen(false)

  useEffect(() => {
    faqRefetch()
  }, [query])

  const handleDeleteVideo = () => {
    if (selectedRows.length < 1) {
      alert('1개 이상의 데이터를 선택해주세요.')
      handleDelClose()

      return
    }

    deleteVideo({ videoGuideIds: selectedRows })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('비디오 가이드를 삭제했습니다.')
          videoRefetch()
          handleDelClose()
          setSelectedRows([])
        }
      })
      .catch((err) => {
        alert(err.data.message)
      })
  }

  const handleDeleteFaq = () => {
    if (selectedRows.length < 1) {
      alert('1개 이상의 데이터를 선택해주세요.')
      handleDelClose()

      return
    }

    deleteFaq({ faqIds: selectedRows })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('FAQ를 삭제했습니다.')
          faqRefetch()
          handleDelClose()
          setSelectedRows([])
        }
      })
      .catch((err) => {
        alert(err.data.message)
      })
  }

  const handleDeleteManual = () => {
    if (selectedRows.length < 1) {
      alert('1개 이상의 데이터를 선택해주세요.')
      handleDelClose()

      return
    }

    deleteManual({ manualIds: selectedRows })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('Manual을 삭제했습니다.')
          manualRefetch()
          handleDelClose()
          setSelectedRows([])
        }
      })
      .catch((err) => {
        alert(err.data.message)
      })
  }

  return (
    <UserGuidePageView
      data1={manualData}
      data2={videoData}
      data3={faqData}
      modelData={modelData}
      isNew={isNew}
      menualOpen={menualOpen}
      faqOpen={faqOpen}
      videoOpen={videoOpen}
      handleFaqClose={handleFaqClose}
      handleFaqOpen={handleFaqOpen}
      handleMenualClose={handleManualClose}
      handleMenualOpen={handleManualOpen}
      handleVideoClose={handleVideoClose}
      handleVideoOpen={handleVideoOpen}
      page={page}
      setPage={setPage}
      setQuery={setQuery}
      query={query}
      videoId={videoId}
      videoRefetch={videoRefetch}
      selectRows={selectedRows}
      setSelectedRows={setSelectedRows}
      handleDeleteVideo={handleDeleteVideo}
      faqRefetch={faqRefetch}
      faqId={faqId}
      handleDeleteFaq={handleDeleteFaq}
      manualRefetch={manualRefetch}
      manualId={manualId}
      handleDeleteManual={handleDeleteManual}
      handleDelClose={handleDelClose}
      handleDelOpen={handleDelOpen}
      delOpen={delOpen}
    />
  )
}

export default UserGuidePage
