import { api } from '../api'

export const userApi = api
  .enhanceEndpoints({
    addTagTypes: ['User'],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      findUserList: builder.query({
        query: (args) => ({
          url: '/user',
          params: args,
        }),
      }),
      findUserListAll: builder.query<any, any>({
        query: (args) => ({
          url: '/user/all',
          params: args,
        }),
      }),
      findUserStat: builder.query({
        query: (args) => ({
          url: '/user/stats',
          params: args,
        }),
      }),
      updateUserStatus: builder.mutation({
        query: (args) => ({
          url: '/user',
          method: 'PUT',
          body: args,
        }),
      }),
    }),
  })

export const {
  useLazyFindUserListQuery,
  useUpdateUserStatusMutation,
  useLazyFindUserStatQuery,
  useLazyFindUserListAllQuery,
} = userApi
