// ** React Imports
import { useEffect, useMemo, useState } from 'react'

// ** Service Imports
import {
  useLazyFindFaqQuery,
  useFindModelListNoPaginationQuery,
  useSaveFaqMutation,
  useUpdateFaqMutation,
} from '../../../services'

// ** Component Imports
import FaqModalView from './faq-modal'

// ** Utils Imports
import { isUndefined } from 'lodash'
import useInput from '../../../hooks/useInput'

// ** Type Imports
import type { FaqParams } from '@/types/faq'

interface Props {
  state: boolean
  isNew: boolean
  faqId: number
  closeEvent: () => void
  faqRefetch: () => void
}

const FaqModal = ({ state, closeEvent, isNew, faqRefetch, faqId }: Props) => {
  const {
    data: faq,
    handler: setFaq,
    setData,
  } = useInput<FaqParams>({
    modelId: 0,
    exposeYn: 'Y',
    title: '',
    text: '',
  })

  const [open, setOpen] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)

  const [saveFaqApi] = useSaveFaqMutation()
  const [updateFaqApi] = useUpdateFaqMutation()
  const [findFaqApi] = useLazyFindFaqQuery()

  const { data: modelList } = useFindModelListNoPaginationQuery('')

  const modelData = useMemo(
    () => (isUndefined(modelList) ? [] : modelList.data.data),
    [modelList],
  )

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleEditOpen = () => setEditOpen(true)
  const handleEditClose = () => setEditOpen(false)

  const regContent = () => {
    if (faq.modelId === 0) {
      alert('모델을 선택해주세요.')

      return
    }

    if (faq.title === '') {
      alert('제목을 입력해주세요.')

      return
    }

    if (faq.text === '') {
      alert('내용을 입력해주세요.')

      return
    }

    saveFaqApi(faq)
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          setData({ modelId: 0, exposeYn: 'Y', title: '', text: '' })
          faqRefetch()
          closeEvent()
        }
      })
      .catch((err) => {
        alert(err.data.message)
      })
  }

  const modContent = () => {
    if (faq.modelId === 0) {
      alert('모델을 선택해주세요.')

      return
    }

    if (faq.title === '') {
      alert('제목을 입력해주세요.')

      return
    }

    if (faq.text === '') {
      alert('내용을 입력해주세요.')

      return
    }

    updateFaqApi(faq)
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          setData({ modelId: 0, exposeYn: 'Y', title: '', text: '' })
          faqRefetch()
          closeEvent()
        }
      })
      .catch((err) => {
        alert(err.data.message)
      })
  }

  useEffect(() => {
    if (!isNew) {
      findFaqApi(faqId)
        .unwrap()
        .then((res) => {
          setData({
            faqId: res.data.id,
            title: res.data.title,
            text: res.data.text,
            exposeYn: res.data.exposeYn,
            modelId: res.data.model.id,
          })
        })
    }
  }, [state])

  return (
    <FaqModalView
      state={state}
      closeEvent={closeEvent}
      isNew={isNew}
      faq={faq}
      setFaq={setFaq}
      modelData={modelData}
      regContent={regContent}
      modContent={modContent}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      editOpen={editOpen}
      handleEditClose={handleEditClose}
      handleEditOpen={handleEditOpen}
    />
  )
}

export default FaqModal
