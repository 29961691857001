import { api } from '../api'

export const policyApi = api
  .enhanceEndpoints({
    addTagTypes: ['Policy'],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      findPolicy: builder.query({
        query: (args) => ({
          url: '/policy/type',
          params: args,
        }),
      }),
      updatePolicy: builder.mutation({
        query: (args) => ({
          url: '/policy',
          method: 'PUT',
          body: args,
        }),
      }),
    }),
  })

export const { useLazyFindPolicyQuery, useUpdatePolicyMutation } = policyApi
