import { api } from '../api'

export const modelApi = api
  .enhanceEndpoints({
    addTagTypes: ['Model'],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      findModelListNoPagination: builder.query({
        query: (any) => ({
          url: '/model',
        }),
      }),
      findModelList: builder.query({
        query: (args) => ({
          url: '/model',
          params: args,
        }),
      }),
      findModel: builder.query({
        query: (id) => ({
          url: `/model/${id}`,
        }),
      }),
      saveModel: builder.mutation({
        query: (args) => ({
          url: '/model',
          method: 'POST',
          body: args,
        }),
      }),
      deleteModel: builder.mutation({
        query: (args) => ({
          url: '/model/delete',
          method: 'POST',
          body: args,
        }),
      }),
      updateModel: builder.mutation({
        query: (args) => ({
          url: '/model',
          method: 'PUT',
          body: args,
        }),
      }),
    }),
  })

export const {
  useFindModelListNoPaginationQuery,
  useFindModelListQuery,
  useSaveModelMutation,
  useUpdateModelMutation,
  useLazyFindModelQuery,
  useDeleteModelMutation,
} = modelApi
