// ** React Imports
import { useEffect, useMemo, useState } from 'react'

// ** Component Imports
import ActivePageView from './active-page'

// ** Type Imports
import { User } from '@/types'
import type { UserParmas } from '@/types/user'

// ** Redux Imports
import {
  useFindModelListNoPaginationQuery,
  useLazyFindUserListQuery,
  useUpdateUserStatusMutation,
  useSaveUserManualMutation,
} from '../../services'

// ** Utils Imports
import { isUndefined } from 'lodash'
import useInput from '../../hooks/useInput'

const UserActivePage = () => {
  const {
    data: query,
    handler: setQuery,
    setData,
  } = useInput<UserParmas>({
    email: '',
    type: '',
    socialType: '',
    lang: '',
    osType: '',
    modelId: 0,
  })

  const [page, setPage] = useState<number>(0)
  const [reRenderSwitch, setReRenderSwitch] = useState<boolean>(false)

  const [userList, setUserList] = useState<User[]>([])
  const [count, setCount] = useState<number>(0)

  const [open, setOpen] = useState<boolean>(false)
  const [openInActive, setOpenInActive] = useState<boolean>(false)
  const [openInDelete, setOpenInDelete] = useState<boolean>(false)
  const [openInOut, setOpenInOut] = useState<boolean>(false)

  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const args = () => {
    const arg: {
      page: number
      pageSize: number
      email?: string
      socialType?: string
      lang?: string
      osType?: string
      type?: string
      modelId?: number
    } = {
      page,
      pageSize: 10,
    }
    if (query.type !== '') arg.type = query.type
    if (query.email !== '') arg.email = query.email
    if (query.socialType !== '') arg.socialType = query.socialType
    if (query.lang !== '') arg.lang = query.lang
    if (query.osType !== '') arg.osType = query.osType
    if (query.modelId !== 0) arg.modelId = Number(query.modelId)

    return arg
  }

  const { data: modelList } = useFindModelListNoPaginationQuery('')

  const modelData = useMemo(
    () => (isUndefined(modelList) ? [] : modelList.data.data),
    [modelList],
  )

  const [findUserApi] = useLazyFindUserListQuery()
  const [updateUserApi] = useUpdateUserStatusMutation()
  const [saveManage] = useSaveUserManualMutation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleInAcvtiveOpen = () => setOpenInActive(true)
  const handleInAcvtiveClose = () => setOpenInActive(false)

  const handleInDeleteOpen = () => setOpenInDelete(true)
  const handleInDeleteClose = () => setOpenInDelete(false)

  const handleInOutOpen = () => setOpenInOut(true)
  const handleInOutClose = () => setOpenInOut(false)

  const handleRefetch = () => setReRenderSwitch(true)
  const handleInitQuery = () => {
    setData({
      email: '',
      type: '',
      socialType: '',
      lang: '',
      osType: '',
      modelId: 0,
    })
    handleRefetch()
  }

  const modContent = (type: string) => {
    updateUserApi({ userIds: selectedRows, type })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          if (type === 'OUT') {
            alert('유저를 삭제했습니다.')
          } else {
            alert('유저 상태를 수정했습니다.')
          }
          handleInAcvtiveClose()
          handleInOutClose()
          handleInDeleteClose()
          handleClose()
          handleClose()
          setSelectedRows([])
          saveManage({
            pageName: '활성화 관리',
            type,
          })
            .unwrap()
            .then((res) => {})

          handleRefetch()
        }
      })
      .catch((err) => alert(err.data.message))
  }

  useEffect(() => {
    if (reRenderSwitch) {
      setReRenderSwitch(false)
    }

    findUserApi(args())
      .unwrap()
      .then((res) => {
        setUserList(res.data.data)
        setCount(res.data.count)
      })
  }, [reRenderSwitch, page])

  useEffect(() => {
    saveManage({ pageName: '활성화 관리', type: 'READ' })
      .unwrap()
      .then((res) => {})
  }, [])

  return (
    <ActivePageView
      data={userList}
      handleRefetch={handleRefetch}
      page={page}
      setPage={setPage}
      count={count}
      query={query}
      setQuery={setQuery}
      modelData={modelData}
      handleInitQuery={handleInitQuery}
      selectRows={selectedRows}
      setSelectedRows={setSelectedRows}
      modContent={modContent}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      openInActive={openInActive}
      handleInAcvtiveClose={handleInAcvtiveClose}
      handleInAcvtiveOpen={handleInAcvtiveOpen}
      handleInDeleteClose={handleInDeleteClose}
      handleInDeleteOpen={handleInDeleteOpen}
      handleInOutClose={handleInOutClose}
      handleInOutOpen={handleInOutOpen}
      openInDelete={openInDelete}
      openInOut={openInOut}
    />
  )
}

export default UserActivePage
