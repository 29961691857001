import { api } from '../api'

export const firmwareApi = api
  .enhanceEndpoints({
    addTagTypes: ['Firmware'],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      findFirmwareList: builder.query({
        query: (args) => ({
          url: '/firmware',
          params: args,
        }),
      }),
      findFirmware: builder.query({
        query: (id) => ({
          url: `/firmware/${id}`,
        }),
      }),
      saveFirmware: builder.mutation({
        query: (args) => ({
          url: '/firmware',
          body: args,
          method: 'POST',
        }),
      }),
      deleteFirmware: builder.mutation({
        query: (args) => ({
          url: '/firmware/delete',
          body: args,
          method: 'POST',
        }),
      }),
      updateFirmware: builder.mutation({
        query: (args) => ({
          url: '/firmware',
          body: args,
          method: 'PUT',
        }),
      }),
    }),
  })

export const {
  useLazyFindFirmwareListQuery,
  useSaveFirmwareMutation,
  useLazyFindFirmwareQuery,
  useUpdateFirmwareMutation,
  useDeleteFirmwareMutation,
} = firmwareApi
