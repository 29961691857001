// ** Router Imports
import { useNavigate } from 'react-router-dom'

// ** Other View Import
import LoginPageView from './login-page'

// ** Otheer Imports
import useInput from '../../hooks/useInput'
import { useDispatch } from 'react-redux'
import { updateUser } from '../../store/app/auth'
import { useLoginMutation } from '../../services'

// ** Type Imports
import type { UserLoginParams } from '@/types/user'

const LoginPage = () => {
  const { data: user, handler: setUser } = useInput<UserLoginParams>({
    username: 'admin',
    password: '1234',
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loginApi] = useLoginMutation()

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      login()
    }
  }

  const login = () => {
    if (user.username === '') {
      alert('아이디를 입력해주세요.')

      return
    }

    if (user.password === '') {
      alert('패스워드를 입력해주세요.')

      return
    }

    loginApi(user)
      .unwrap()
      .then(({ data }) => {
        dispatch(
          updateUser({
            username: data.user.username,
            grade: data.user.grade,
            accessToken: data.token.accessToken,
            refreshToken: data.token.refreshToken,
          }),
        )
        navigate('/user')
      })
      .catch((err) => {
        alert(err.data.message)
      })
  }

  return (
    <LoginPageView
      user={user}
      setUser={setUser}
      login={login}
      handleOnKeyPress={handleOnKeyPress}
    />
  )
}

export default LoginPage
