// ** Mui Imports
import { Dialog, DialogTitle, Grid, TextField, Button } from '@mui/material'

// ** Component Imports
import SelectModal from '../../../components/modal/select-modal'

interface Props {
  state: boolean
  isNew: boolean
  open: boolean
  editOpen: boolean
  history: { admin: string; title: string; text: string }
  cancelOpen: boolean
  setHistory: (e: any) => void
  handleOpen: () => void
  handleClose: () => void
  handleCancelOpen: () => void
  handleCancelClose: () => void
  regContent: () => void
  closeEvent: () => void
  modContent: () => void
  handleEditOpen: () => void
  handleEditClose: () => void
}

const AdminHistoryModalView = ({
  state,
  closeEvent,
  isNew,
  history,
  setHistory,
  open,
  handleClose,
  handleOpen,
  regContent,
  cancelOpen,
  handleCancelClose,
  handleCancelOpen,
  modContent,
  editOpen,
  handleEditClose,
  handleEditOpen,
}: Props) => {
  return (
    <Dialog
      maxWidth="md"
      open={state}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEvent()
        }
      }}
      fullWidth
    >
      <DialogTitle>운영활동 {isNew ? '등록' : '수정'}</DialogTitle>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="관리자 이름을 입력하세요."
            label="관리자명"
            value={history.admin}
            name="admin"
            onChange={setHistory}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="제목을 입력하세요."
            label="제목"
            value={history.title}
            name="title"
            onChange={setHistory}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="내용을 입력하세요."
            label="내용"
            multiline
            rows={30}
            value={history.text}
            name="text"
            onChange={setHistory}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={isNew ? handleOpen : handleEditOpen}
          >
            {isNew ? '저장' : '수정'}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="error" onClick={handleCancelOpen}>
            취소
          </Button>
        </Grid>
        <Grid item xs={4} />
        {open && (
          <SelectModal
            state={open}
            closeEvent={handleClose}
            event={regContent}
            message="정말 등록하시겠습니까?"
            one="등록"
            two="닫기"
          />
        )}
        {editOpen && (
          <SelectModal
            state={editOpen}
            closeEvent={handleEditClose}
            event={modContent}
            message="정말 수정하시겠습니까?"
            one="수정"
            two="닫기"
          />
        )}
        {cancelOpen && (
          <SelectModal
            state={cancelOpen}
            closeEvent={handleCancelClose}
            event={closeEvent}
            message="작성한 내용이 사라집니다. 정말 취소하시겠습니까?"
            one="확인"
            two="닫기"
          />
        )}
      </Grid>
    </Dialog>
  )
}

export default AdminHistoryModalView
