// ** React Imports
import { useEffect, useMemo, useState } from 'react'

// ** Component Imports
import VideoGuideModalView from './video-guide-modal'

//** Redux Imports
import {
  useFindModelListNoPaginationQuery,
  useLazyFindVideoQuery,
  useSaveVideoMutation,
  useUpdateVideoMutation,
} from '../../../services'

// ** Utils Imports
import { isUndefined } from 'lodash'
import useInput from '../../../hooks/useInput'

// ** Type Imports
import type { VideoGuideParams } from '@/types/videoGuide'

interface Props {
  state: boolean
  isNew: boolean
  videoId: number
  closeEvent: () => void
  videoRefetch: () => void
}

const VideoGuidelModal = ({
  state,
  closeEvent,
  isNew,
  videoId,
  videoRefetch,
}: Props) => {
  const {
    data: video,
    handler: setVideo,
    setData,
  } = useInput<VideoGuideParams>({
    modelId: 0,
    link: '',
  })

  const [open, setOpen] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleEditOpen = () => setEditOpen(true)
  const handleEditClose = () => setEditOpen(false)

  const [saveVideoApi] = useSaveVideoMutation()
  const [updateVideoApi] = useUpdateVideoMutation()
  const [findVideoApi] = useLazyFindVideoQuery()

  const { data: modelList } = useFindModelListNoPaginationQuery('')

  const modelData = useMemo(
    () => (isUndefined(modelList) ? [] : modelList.data.data),
    [modelList],
  )

  const regContent = () => {
    if (video.modelId === 0) {
      alert('모델을 선택해주세요.')

      return
    }

    if (video.link === '') {
      alert('링크를 입력해주세요.')

      return
    }

    saveVideoApi(video)
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          setData({ modelId: 0, link: '' })
          videoRefetch()
          closeEvent()
          handleClose()
        }
      })
      .catch((err) => {
        alert(err.data.message)
        handleClose()
      })
  }

  const modContent = () => {
    if (video.modelId === 0) {
      alert('모델을 선택해주세요.')

      return
    }

    if (video.link === '') {
      alert('링크를 입력해주세요.')

      return
    }

    updateVideoApi(video)
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          videoRefetch()
          setData({ modelId: 0, link: '' })
          closeEvent()
          handleEditClose()
        }
      })
      .catch((err) => {
        alert(err.data.message)
        handleEditClose()
      })
  }

  useEffect(() => {
    if (!isNew) {
      findVideoApi(videoId)
        .unwrap()
        .then((res) => {
          setData({
            modelId: res.data.model.id,
            link: res.data.link,
            videoGuideId: res.data.id,
          })
        })
    }
  }, [state])

  return (
    <VideoGuideModalView
      state={state}
      closeEvent={closeEvent}
      isNew={isNew}
      video={video}
      modelData={modelData}
      setVideo={setVideo}
      regContent={regContent}
      modContent={modContent}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      editOpen={editOpen}
      handleEditClose={handleEditClose}
      handleEditOpen={handleEditOpen}
    />
  )
}

export default VideoGuidelModal
