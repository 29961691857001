// ** Component Imports
import AdminModalView from './admin-page-modal'

// ** Utils Imports
import useInput from '../../../hooks/useInput'

// ** Redux Imports
import { useSaveAdminMutation } from '../../../services'
import { useState } from 'react'

interface Props {
  state: boolean
  isNew: boolean
  handleRefetch: () => void
  closeEvent: () => void
}

const englishAndNumbersRegex = /^[A-Za-z0-9]+$/

const AdminModal = ({ state, closeEvent, isNew, handleRefetch }: Props) => {
  const {
    data: admin,
    handler: setAdmin,
    setData,
  } = useInput({
    username: '',
    password: '',
    passwordC: '',
  })

  const [open, setOpen] = useState<boolean>(false)

  const [saveAdminApi] = useSaveAdminMutation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const regContent = () => {
    if (admin.username === '') {
      alert('아이디를 입력하세요.')

      handleClose()
      return
    }

    if (admin.username.length < 4) {
      alert('아이디는 4글자 이상으로 입력해주세요.')

      handleClose()
      return
    }

    if (!englishAndNumbersRegex.test(admin.username)) {
      alert('영어와 숫자만 입력 가능합니다.')

      handleClose()
      return
    }

    if (admin.password === '') {
      alert('비밀번호를 입력하세요.')

      handleClose()
      return
    }

    if (admin.password.length < 6) {
      alert('비밀번호는 6글자 이상으로 입력해주세요.')

      handleClose()
      return
    }

    if (admin.password !== admin.passwordC) {
      alert('비밀번호와 비밀번호 확인이 같지 않습니다.')

      handleClose()

      return
    }

    saveAdminApi(admin)
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('관리자를 등록했습니다.')
          setData({ username: '', password: '', passwordC: '' })
          handleClose()
          handleRefetch()
          closeEvent()
        }
      })
      .catch((err) => alert(err.data.message))
  }

  return (
    <AdminModalView
      state={state}
      closeEvent={closeEvent}
      isNew={isNew}
      handleClose={handleClose}
      handleOpen={handleOpen}
      open={open}
      admin={admin}
      setAdmin={setAdmin}
      regContent={regContent}
    />
  )
}

export default AdminModal
