// ** Mui Imports
import {
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
} from '@mui/material'

interface Props {
  state: boolean
  appVersion: { type: string; text: string; version: string }
  setAppVersion: (e: any) => void
  closeEvent(): void
  regContent: () => void
}

const AppVersionModalView = ({
  state,
  closeEvent,
  appVersion,
  setAppVersion,
  regContent,
}: Props) => {
  return (
    <Dialog
      maxWidth="sm"
      open={state}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEvent()
        }
      }}
      fullWidth
    >
      <DialogTitle>버전 정보 업데이트</DialogTitle>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="model">플랫폼</InputLabel>
            <Select
              labelId="model"
              label="플랫폼"
              value={appVersion.type}
              name="type"
              onChange={setAppVersion}
            >
              <MenuItem value="">선택</MenuItem>
              <MenuItem value="iOS">iOS</MenuItem>
              <MenuItem value="AOS">AOS</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="내용"
            placeholder="업데이트 내용을 입력해주세요."
            onChange={setAppVersion}
            value={appVersion.text}
            inputProps={{ maxLength: 15 }}
            name="text"
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'right', m: -2 }}>
          <Typography>
            {appVersion.text.replace(/<br\s*\/?>/gm, '\n').length}/15
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="버전"
            placeholder="업데이트 버전을 입력해주세요."
            onChange={setAppVersion}
            value={appVersion.version}
            name="version"
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2}>
          <Button variant="contained" color="error" onClick={closeEvent}>
            취소
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={regContent}>
            저장
          </Button>
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </Dialog>
  )
}

export default AppVersionModalView
