// ** React Imports
import { useEffect, useState } from 'react'

// ** Component Imports
import AdminHistoryPageView from './admin-history-page'

// ** Type Imports
import { AdminHistory, Dates } from '@/types'

// ** Redux Imports
import {
  useDeleteAdminHistoryMutation,
  useLazyFindAdminHistoryListQuery,
} from '../../services'

// ** Util Imports
import dayjs from 'dayjs'
import useInput from '../../hooks/useInput'

const AdminHistoryPage = () => {
  const [page, setPage] = useState<number>(0)

  const [dates, setDates] = useState<Dates>({
    startDate: dayjs().subtract(1, 'month'),
    endDate: dayjs(),
  })

  const {
    data: query,
    handler: setQuery,
    setData: setQueryData,
  } = useInput({ title: '', admin: '' })

  const [data, setData] = useState<AdminHistory[]>([])
  const [count, setCount] = useState<number>(0)
  const [historyId, setHistoryId] = useState<number>(0)

  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const [reRenderSwitch, setReRenderSwitch] = useState<boolean>(false)

  const [open, setOpen] = useState<boolean>(false)
  const [delOpen, setDelOpen] = useState<boolean>(false)
  const [isNew, setIsNew] = useState<boolean>(false)

  const [findAdminHistoryListApi] = useLazyFindAdminHistoryListQuery()
  const [deleteAdminHistoryApi] = useDeleteAdminHistoryMutation()

  const handleRefetch = () => setReRenderSwitch(true)
  const handleQueryInit = () => {
    setQueryData({ title: '', admin: '' })
    setDates({ startDate: dayjs().subtract(1, 'month'), endDate: dayjs() })
    setPage(0)
    handleRefetch()
  }

  const handleOpen = (state: boolean, id?: number) => {
    setOpen(true)
    setIsNew(state)
    if (id) {
      setHistoryId(id)
    }
  }
  const handleDelOpen = () => setDelOpen(true)
  const handleClose = () => setOpen(false)
  const handleDelClose = () => setDelOpen(false)

  const args = () => {
    const arg: {
      page: number
      pageSize: number
      startDate: string
      endDate: string
      title?: string
      admin?: string
    } = {
      page,
      pageSize: 10,
      startDate: dates.startDate.format('YYYY-MM-DD'),
      endDate: dates.endDate.add(1, 'day').format('YYYY-MM-DD'),
    }

    if (query.title !== '') arg.title = query.title
    if (query.admin !== '') arg.admin = query.admin

    return arg
  }

  const delContent = () => {
    if (selectedRows.length < 1) {
      alert('1개 이상을 선택해주세요.')
      handleDelClose()
      return
    }

    deleteAdminHistoryApi({ logIds: selectedRows })
      .unwrap()
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          alert('삭제되었습니다.')
          handleRefetch()
          handleDelClose()
          setSelectedRows([])
        }
      })
      .catch((err) => alert(err.data.message))
  }

  useEffect(() => {
    if (reRenderSwitch) {
      setReRenderSwitch(false)
    }

    findAdminHistoryListApi(args())
      .unwrap()
      .then((res) => {
        setData(res.data.data)
        setCount(res.data.count)
      })
  }, [page, reRenderSwitch])

  return (
    <AdminHistoryPageView
      data={data}
      open={open}
      handleClose={handleClose}
      isNew={isNew}
      handleOpen={handleOpen}
      query={query}
      setQuery={setQuery}
      page={page}
      setPage={setPage}
      count={count}
      dates={dates}
      setDates={setDates}
      handleRefetch={handleRefetch}
      handleQueryInit={handleQueryInit}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      historyId={historyId}
      delContent={delContent}
      delOpen={delOpen}
      handleDelClose={handleDelClose}
      handleDelOpen={handleDelOpen}
    />
  )
}

export default AdminHistoryPage
